* {
  margin: 0;
  padding: 0; }

a,
a:hover,
a:focus {
  text-decoration: none;
  outline: none;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

img,
iframe,
video {
  max-width: 100%; }

table {
  width: 100%; }

img {
  border: none;
  max-width: 100%;
  height: auto; }

ol,
ul {
  list-style: none; }

fieldset {
  border: 2px groove threedface;
  display: block;
  padding-bottom: 0.75em;
  padding-top: 0.35em;
  text-align: center; }

fieldset legend {
  display: inline-block;
  color: inherit;
  background: none;
  vertical-align: top; }

::-webkit-selection {
  background: #DFB1A7;
  color: #ffffff; }

::-moz-selection {
  background: #DFB1A7;
  color: #ffffff; }

::selection {
  background: #DFB1A7;
  color: #ffffff; }

::-webkit-input-placeholder {
  -webkit-transition: text-indent 0.5s ease, color 0.5s ease;
  transition: text-indent 0.5s ease, color 0.5s ease;
  color: #969696; }

input::-moz-placeholder {
  -webkit-transition: text-indent 0.5s ease, color 0.5s ease;
  transition: text-indent 0.5s ease, color 0.5s ease;
  color: #969696; }

input::ms-input-placeholder {
  -webkit-transition: text-indent 0.5s ease, color 0.5s ease;
  transition: text-indent 0.5s ease, color 0.5s ease;
  color: #969696; }

[placeholder]:focus::-webkit-input-placeholder {
  text-indent: 10em;
  color: transparent; }

[placeholder]:focus::-moz-placeholder {
  text-indent: 10em;
  color: transparent; }

body,
html {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #000000;
  background: #ffffff;
  font-size: 14px;
  overflow-x: hidden; }

.title {
  font-family: "PlayfairDisplay", cursive; }

.color-main {
  color: #000000; }

.form-control,
button,
input,
select {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  outline: none !important;
  box-shadow: none;
  cursor: pointer; }
  .form-control:hover, .form-control:focus, .form-control:active,
  button:hover,
  button:focus,
  button:active,
  input:hover,
  input:focus,
  input:active,
  select:hover,
  select:focus,
  select:active {
    outline: none !important;
    box-shadow: none !important;
    border-color: #000000; }

/*custom Scroll Chrome */
::-webkit-scrollbar {
  width: 6px;
  height: 6px; }

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px; }

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(105, 105, 105, 0.8);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); }

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(105, 105, 105, 0.4); }

b,
strong {
  font-weight: 700; }

/* Bootstrap Overrides */
@media (min-width: 1024px) {
  .container {
    max-width: 1024px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1200px; } }

@media (min-width: 992px) {
  .col-lg-5th {
    -webkit-box-flex: 0;
            flex: 0 0 20%;
    max-width: 20%; } }

body {
  padding-right: 0px !important; }

body {
  min-height: 100vh; }

.main-title {
  font-size: 32px;
  line-height: 36px;
  font-family: "PlayfairDisplay";
  font-style: italic;
  font-weight: normal;
  margin-bottom: 0; }
  @media (min-width: 991px) {
    .main-title {
      font-size: 40px;
      line-height: 48px; } }

.lds-grid {
  display: block;
  position: relative;
  width: 40px;
  height: 40px; }

.lds-grid div {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #000000;
  -webkit-animation: lds-grid 1.2s linear infinite;
          animation: lds-grid 1.2s linear infinite; }

.lds-grid div:nth-child(1) {
  top: 4px;
  left: 4px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s; }

.lds-grid div:nth-child(2) {
  top: 4px;
  left: 16px;
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s; }

.lds-grid div:nth-child(3) {
  top: 4px;
  left: 28px;
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; }

.lds-grid div:nth-child(4) {
  top: 16px;
  left: 4px;
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s; }

.lds-grid div:nth-child(5) {
  top: 16px;
  left: 16px;
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; }

.lds-grid div:nth-child(6) {
  top: 16px;
  left: 28px;
  -webkit-animation-delay: -1.2s;
          animation-delay: -1.2s; }

.lds-grid div:nth-child(7) {
  top: 28px;
  left: 4px;
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; }

.lds-grid div:nth-child(8) {
  top: 28px;
  left: 16px;
  -webkit-animation-delay: -1.2s;
          animation-delay: -1.2s; }

.lds-grid div:nth-child(9) {
  top: 28px;
  left: 28px;
  -webkit-animation-delay: -1.6s;
          animation-delay: -1.6s; }

@-webkit-keyframes lds-grid {
  0%,
  100% {
    opacity: 1; }
  50% {
    opacity: 0.5; } }

@keyframes lds-grid {
  0%,
  100% {
    opacity: 1; }
  50% {
    opacity: 0.5; } }

.lds-ring {
  position: relative;
  width: 35px; }

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  height: 35px;
  border: 5px solid #DFB1A7;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #DFB1A7 transparent transparent transparent; }

.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s; }

.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s; }

.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s; }

@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.hotels {
  background-color: #242D33;
  padding: 36px 0 0 0;
  margin-bottom: 5px; }
  .hotels .container {
    padding: 0; }
  .hotels__title {
    font-family: "PlayfairDisplay";
    font-style: italic;
    font-weight: normal;
    font-size: 28px;
    line-height: 37px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 0;
    padding-bottom: 17px; }
  .hotels__notfound {
    font-size: 14px;
    line-height: 150%;
    color: #ffffff; }
  .hotels .item {
    color: #ffffff;
    border-top: 3px solid #ffffff; }
    .hotels .item:first-child {
      border-top: none; }
    .hotels .item a {
      color: #ffffff;
      display: inline-block;
      height: 100%;
      width: 100%; }
    .hotels .item__inner {
      position: relative;
      display: -webkit-box;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
              flex-direction: column;
      height: 100%; }
    .hotels .item__image {
      width: 100%;
      position: relative; }
      .hotels .item__image .img-mask {
        width: 100%;
        visibility: hidden;
        overflow: hidden; }
      .hotels .item__image .img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
           object-fit: cover; }
    .hotels .item__caption {
      position: relative;
      width: 100%;
      background-color: #000000;
      padding: 13px 60px 24px 16px;
      -webkit-box-flex: 1;
              flex: 1; }
      .hotels .item__caption--no-rating {
        padding: 13px 16px; }
    .hotels .item__info, .hotels .item__type {
      position: relative;
      z-index: 2; }
    .hotels .item__type {
      font-size: 8px;
      line-height: 10px;
      letter-spacing: 0.3em;
      margin-bottom: 8px;
      text-transform: uppercase; }
    .hotels .item__title {
      font-style: italic;
      font-weight: bold;
      margin-bottom: 5px;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0.02em; }
    .hotels .item__link {
      color: #ffffff;
      font-family: "PlayfairDisplay";
      margin-bottom: 0; }
    .hotels .item__location {
      font-size: 11px;
      line-height: 13px;
      margin-bottom: 0; }
    .hotels .item__ranking {
      font-weight: bold;
      font-size: 20px;
      line-height: 1;
      text-align: center;
      color: #ffffff;
      font-family: "PlayfairDisplay";
      width: 38px;
      height: 38px;
      position: absolute;
      top: 14px;
      right: 16px;
      z-index: 2;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
              align-items: center;
      -webkit-box-pack: center;
              justify-content: center; }
      .hotels .item__ranking__border {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto; }
    .hotels .item__number {
      display: inline-block;
      position: relative;
      margin-top: -6px; }
    .hotels .item__hash {
      display: inline-block;
      position: absolute;
      top: -8px;
      left: -5px; }
  .hotels--search {
    padding: 18px 0 0 0;
    background-color: #ffffff; }
  @media (min-width: 768px) {
    .hotels {
      padding: 48px 0 50px 0;
      margin-bottom: 0; }
      .hotels__title {
        margin-bottom: 0;
        padding-bottom: 15px; }
      .hotels__inner {
        display: -webkit-box;
        display: flex;
        flex-wrap: wrap;
        margin: 0 6px; }
      .hotels__notfound {
        font-size: 15px;
        line-height: 22px; }
      .hotels .item {
        margin-top: 12px;
        width: 33.33333%;
        padding: 0 6px;
        border-top: none; }
        .hotels .item__caption {
          padding: 12px 50px 12px 15px; }
          .hotels .item__caption--no-rating {
            padding: 12px 15px; }
        .hotels .item__type {
          font-size: 7px;
          line-height: 9px;
          letter-spacing: 0.3em;
          margin-bottom: 9px; }
        .hotels .item__title {
          font-size: 13px;
          line-height: 17px;
          letter-spacing: 0.02em;
          margin-bottom: 3px; }
        .hotels .item__location {
          font-size: 9px;
          line-height: 11px;
          letter-spacing: 0.05em; }
        .hotels .item__ranking {
          top: 12px;
          right: 15px;
          width: 30px;
          height: 30px;
          font-size: 16px; }
        .hotels .item__number {
          margin-top: -1px; }
      .hotels--search {
        background-color: #242D33;
        padding: 50px 0; } }
  @media (min-width: 991px) {
    .hotels {
      padding: 80px 0 80px 0; }
      .hotels__title {
        font-size: 40px;
        line-height: 53px; }
      .hotels__inner {
        margin: 0 -10px; }
      .hotels .item {
        padding: 0 10px;
        margin-top: 20px; }
        .hotels .item__caption {
          padding: 15px 70px 15px 16px; }
          .hotels .item__caption--no-rating {
            padding: 15px 16px; }
        .hotels .item__type {
          font-size: 8px;
          line-height: 10px;
          letter-spacing: 0.3em;
          margin-bottom: 17px; }
        .hotels .item__title {
          font-size: 20px;
          line-height: 27px;
          margin-bottom: 4px; }
        .hotels .item__location {
          font-size: 13px;
          line-height: 16px;
          letter-spacing: 0.05em; }
        .hotels .item__ranking {
          top: 14px;
          right: 16px;
          width: 48px;
          height: 48px;
          font-size: 25px; }
        .hotels .item__number {
          margin-top: -6px; }
        .hotels .item__hash {
          top: -12px;
          left: -8px; }
          .hotels .item__hash svg {
            width: 7px;
            height: auto; }
      .hotels--search {
        padding: 80px 0; } }

.policy,
.default-page {
  padding-bottom: 39px;
  padding-top: 109px; }
  @media (min-width: 768px) {
    .policy,
    .default-page {
      padding-top: 122px;
      padding-bottom: 66px; } }
  @media (min-width: 1025px) {
    .policy,
    .default-page {
      padding-top: 164px;
      padding-bottom: 80px; } }

@font-face {
  font-family: "Montserrat";
  src: url("/wp-content/themes/steen/app/public/font/Montserrat-Regular.eot");
  src: url("/wp-content/themes/steen/app/public/font/Montserrat-Regular.ttf") format("truetype"), url("/wp-content/themes/steen/app/public/font/Montserrat-Regular.eot?#iefix") format("embedded-opentype"), url("/wp-content/themes/steen/app/public/font/Montserrat-Regular.woff") format("woff"), url("/wp-content/themes/steen/app/public/font/Montserrat-Regular.svg#ywftsvg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Montserrat";
  src: url("/wp-content/themes/steen/app/public/font/Montserrat-Italic.eot");
  src: url("/wp-content/themes/steen/app/public/font/Montserrat-Italic.ttf") format("truetype"), url("/wp-content/themes/steen/app/public/font/Montserrat-Italic.eot?#iefix") format("embedded-opentype"), url("/wp-content/themes/steen/app/public/font/Montserrat-Italic.woff") format("woff"), url("/wp-content/themes/steen/app/public/font/Montserrat-Italic.svg#ywftsvg") format("svg");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: "Montserrat";
  src: url("/wp-content/themes/steen/app/public/font/Montserrat-Thin.eot");
  src: url("/wp-content/themes/steen/app/public/font/Montserrat-Thin.ttf") format("truetype"), url("/wp-content/themes/steen/app/public/font/Montserrat-Thin.eot?#iefix") format("embedded-opentype"), url("/wp-content/themes/steen/app/public/font/Montserrat-Thin.woff") format("woff"), url("/wp-content/themes/steen/app/public/font/Montserrat-Thin.svg#ywftsvg") format("svg");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: "Montserrat";
  src: url("/wp-content/themes/steen/app/public/font/Montserrat-ThinItalic.eot");
  src: url("/wp-content/themes/steen/app/public/font/Montserrat-ThinItalic.ttf") format("truetype"), url("/wp-content/themes/steen/app/public/font/Montserrat-ThinItalic.eot?#iefix") format("embedded-opentype"), url("/wp-content/themes/steen/app/public/font/Montserrat-ThinItalic.woff") format("woff"), url("/wp-content/themes/steen/app/public/font/Montserrat-ThinItalic.svg#ywftsvg") format("svg");
  font-weight: 100;
  font-style: italic; }

@font-face {
  font-family: "Montserrat";
  src: url("/wp-content/themes/steen/app/public/font/Montserrat-ExtraLight.eot");
  src: url("/wp-content/themes/steen/app/public/font/Montserrat-ExtraLight.ttf") format("truetype"), url("/wp-content/themes/steen/app/public/font/Montserrat-ExtraLight.eot?#iefix") format("embedded-opentype"), url("/wp-content/themes/steen/app/public/font/Montserrat-ExtraLight.woff") format("woff"), url("/wp-content/themes/steen/app/public/font/Montserrat-ExtraLight.svg#ywftsvg") format("svg");
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: "Montserrat";
  src: url("/wp-content/themes/steen/app/public/font/Montserrat-ExtraLightItalic.eot");
  src: url("/wp-content/themes/steen/app/public/font/Montserrat-ExtraLightItalic.ttf") format("truetype"), url("/wp-content/themes/steen/app/public/font/Montserrat-ExtraLightItalic.eot?#iefix") format("embedded-opentype"), url("/wp-content/themes/steen/app/public/font/Montserrat-ExtraLightItalic.woff") format("woff"), url("/wp-content/themes/steen/app/public/font/Montserrat-ExtraLightItalic.svg#ywftsvg") format("svg");
  font-weight: 200;
  font-style: italic; }

@font-face {
  font-family: "Montserrat";
  src: url("/wp-content/themes/steen/app/public/font/Montserrat-Light.eot");
  src: url("/wp-content/themes/steen/app/public/font/Montserrat-Light.ttf") format("truetype"), url("/wp-content/themes/steen/app/public/font/Montserrat-Light.eot?#iefix") format("embedded-opentype"), url("/wp-content/themes/steen/app/public/font/Montserrat-Light.woff") format("woff"), url("/wp-content/themes/steen/app/public/font/Montserrat-Light.svg#ywftsvg") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Montserrat";
  src: url("/wp-content/themes/steen/app/public/font/Montserrat-LightItalic.eot");
  src: url("/wp-content/themes/steen/app/public/font/Montserrat-LightItalic.ttf") format("truetype"), url("/wp-content/themes/steen/app/public/font/Montserrat-LightItalic.eot?#iefix") format("embedded-opentype"), url("/wp-content/themes/steen/app/public/font/Montserrat-LightItalic.woff") format("woff"), url("/wp-content/themes/steen/app/public/font/Montserrat-LightItalic.svg#ywftsvg") format("svg");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: "Montserrat";
  src: url("/wp-content/themes/steen/app/public/font/Montserrat-Medium.eot");
  src: url("/wp-content/themes/steen/app/public/font/Montserrat-Medium.ttf") format("truetype"), url("/wp-content/themes/steen/app/public/font/Montserrat-Medium.eot?#iefix") format("embedded-opentype"), url("/wp-content/themes/steen/app/public/font/Montserrat-Medium.woff") format("woff"), url("/wp-content/themes/steen/app/public/font/Montserrat-Medium.svg#ywftsvg") format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "Montserrat";
  src: url("/wp-content/themes/steen/app/public/font/Montserrat-MediumItalic.eot");
  src: url("/wp-content/themes/steen/app/public/font/Montserrat-MediumItalic.ttf") format("truetype"), url("/wp-content/themes/steen/app/public/font/Montserrat-MediumItalic.eot?#iefix") format("embedded-opentype"), url("/wp-content/themes/steen/app/public/font/Montserrat-MediumItalic.woff") format("woff"), url("/wp-content/themes/steen/app/public/font/Montserrat-MediumItalic.svg#ywftsvg") format("svg");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: "Montserrat";
  src: url("/wp-content/themes/steen/app/public/font/Montserrat-SemiBold.eot");
  src: url("/wp-content/themes/steen/app/public/font/Montserrat-SemiBold.ttf") format("truetype"), url("/wp-content/themes/steen/app/public/font/Montserrat-SemiBold.eot?#iefix") format("embedded-opentype"), url("/wp-content/themes/steen/app/public/font/Montserrat-SemiBold.woff") format("woff"), url("/wp-content/themes/steen/app/public/font/Montserrat-SemiBold.svg#ywftsvg") format("svg");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: "Montserrat";
  src: url("/wp-content/themes/steen/app/public/font/Montserrat-SemiBoldItalic.eot");
  src: url("/wp-content/themes/steen/app/public/font/Montserrat-SemiBoldItalic.ttf") format("truetype"), url("/wp-content/themes/steen/app/public/font/Montserrat-SemiBoldItalic.eot?#iefix") format("embedded-opentype"), url("/wp-content/themes/steen/app/public/font/Montserrat-SemiBoldItalic.woff") format("woff"), url("/wp-content/themes/steen/app/public/font/Montserrat-SemiBoldItalic.svg#ywftsvg") format("svg");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: "Montserrat";
  src: url("/wp-content/themes/steen/app/public/font/Montserrat-Bold.eot");
  src: url("/wp-content/themes/steen/app/public/font/Montserrat-Bold.ttf") format("truetype"), url("/wp-content/themes/steen/app/public/font/Montserrat-Bold.eot?#iefix") format("embedded-opentype"), url("/wp-content/themes/steen/app/public/font/Montserrat-Bold.woff") format("woff"), url("/wp-content/themes/steen/app/public/font/Montserrat-Bold.svg#ywftsvg") format("svg");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "Montserrat";
  src: url("/wp-content/themes/steen/app/public/font/Montserrat-BoldItalic.eot");
  src: url("/wp-content/themes/steen/app/public/font/Montserrat-BoldItalic.ttf") format("truetype"), url("/wp-content/themes/steen/app/public/font/Montserrat-BoldItalic.eot?#iefix") format("embedded-opentype"), url("/wp-content/themes/steen/app/public/font/Montserrat-BoldItalic.woff") format("woff"), url("/wp-content/themes/steen/app/public/font/Montserrat-BoldItalic.svg#ywftsvg") format("svg");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: "Montserrat";
  src: url("/wp-content/themes/steen/app/public/font/Montserrat-ExtraBold.eot");
  src: url("/wp-content/themes/steen/app/public/font/Montserrat-ExtraBold.ttf") format("truetype"), url("/wp-content/themes/steen/app/public/font/Montserrat-ExtraBold.eot?#iefix") format("embedded-opentype"), url("/wp-content/themes/steen/app/public/font/Montserrat-ExtraBold.woff") format("woff"), url("/wp-content/themes/steen/app/public/font/Montserrat-ExtraBold.svg#ywftsvg") format("svg");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: "Montserrat";
  src: url("/wp-content/themes/steen/app/public/font/Montserrat-ExtraBoldItalic.eot");
  src: url("/wp-content/themes/steen/app/public/font/Montserrat-ExtraBoldItalic.ttf") format("truetype"), url("/wp-content/themes/steen/app/public/font/Montserrat-ExtraBoldItalic.eot?#iefix") format("embedded-opentype"), url("/wp-content/themes/steen/app/public/font/Montserrat-ExtraBoldItalic.woff") format("woff"), url("/wp-content/themes/steen/app/public/font/Montserrat-ExtraBoldItalic.svg#ywftsvg") format("svg");
  font-weight: 800;
  font-style: italic; }

@font-face {
  font-family: "Montserrat";
  src: url("/wp-content/themes/steen/app/public/font/Montserrat-Black.eot");
  src: url("/wp-content/themes/steen/app/public/font/Montserrat-Black.ttf") format("truetype"), url("/wp-content/themes/steen/app/public/font/Montserrat-Black.eot?#iefix") format("embedded-opentype"), url("/wp-content/themes/steen/app/public/font/Montserrat-Black.woff") format("woff"), url("/wp-content/themes/steen/app/public/font/Montserrat-Black.svg#ywftsvg") format("svg");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: "Montserrat";
  src: url("/wp-content/themes/steen/app/public/font/Montserrat-BlackItalic.eot");
  src: url("/wp-content/themes/steen/app/public/font/Montserrat-BlackItalic.ttf") format("truetype"), url("/wp-content/themes/steen/app/public/font/Montserrat-BlackItalic.eot?#iefix") format("embedded-opentype"), url("/wp-content/themes/steen/app/public/font/Montserrat-BlackItalic.woff") format("woff"), url("/wp-content/themes/steen/app/public/font/Montserrat-BlackItalic.svg#ywftsvg") format("svg");
  font-weight: 900;
  font-style: italic; }

@font-face {
  font-family: "PlayfairDisplay";
  src: url("/wp-content/themes/steen/app/public/font/PlayfairDisplay-Regular.eot");
  src: url("/wp-content/themes/steen/app/public/font/PlayfairDisplay-Regular.ttf") format("truetype"), url("/wp-content/themes/steen/app/public/font/PlayfairDisplay-Regular.eot?#iefix") format("embedded-opentype"), url("/wp-content/themes/steen/app/public/font/PlayfairDisplay-Regular.woff") format("woff"), url("/wp-content/themes/steen/app/public/font/PlayfairDisplay-Regular.svg#ywftsvg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "PlayfairDisplay";
  src: url("/wp-content/themes/steen/app/public/font/PlayfairDisplay-Italic.eot");
  src: url("/wp-content/themes/steen/app/public/font/PlayfairDisplay-Italic.ttf") format("truetype"), url("/wp-content/themes/steen/app/public/font/PlayfairDisplay-Italic.eot?#iefix") format("embedded-opentype"), url("/wp-content/themes/steen/app/public/font/PlayfairDisplay-Italic.woff") format("woff"), url("/wp-content/themes/steen/app/public/font/PlayfairDisplay-Italic.svg#ywftsvg") format("svg");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: "PlayfairDisplay";
  src: url("/wp-content/themes/steen/app/public/font/PlayfairDisplay-Bold.eot");
  src: url("/wp-content/themes/steen/app/public/font/PlayfairDisplay-Bold.ttf") format("truetype"), url("/wp-content/themes/steen/app/public/font/PlayfairDisplay-Bold.eot?#iefix") format("embedded-opentype"), url("/wp-content/themes/steen/app/public/font/PlayfairDisplay-Bold.woff") format("woff"), url("/wp-content/themes/steen/app/public/font/PlayfairDisplay-Bold.svg#ywftsvg") format("svg");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "PlayfairDisplay";
  src: url("/wp-content/themes/steen/app/public/font/PlayfairDisplay-BlackItalic.eot");
  src: url("/wp-content/themes/steen/app/public/font/PlayfairDisplay-BlackItalic.ttf") format("truetype"), url("/wp-content/themes/steen/app/public/font/PlayfairDisplay-BlackItalic.eot?#iefix") format("embedded-opentype"), url("/wp-content/themes/steen/app/public/font/PlayfairDisplay-BlackItalic.woff") format("woff"), url("/wp-content/themes/steen/app/public/font/PlayfairDisplay-BlackItalic.svg#ywftsvg") format("svg");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: "PlayfairDisplay";
  src: url("/wp-content/themes/steen/app/public/font/PlayfairDisplay-Black.eot");
  src: url("/wp-content/themes/steen/app/public/font/PlayfairDisplay-Black.ttf") format("truetype"), url("/wp-content/themes/steen/app/public/font/PlayfairDisplay-Black.eot?#iefix") format("embedded-opentype"), url("/wp-content/themes/steen/app/public/font/PlayfairDisplay-Black.woff") format("woff"), url("/wp-content/themes/steen/app/public/font/PlayfairDisplay-Black.svg#ywftsvg") format("svg");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: "PlayfairDisplay";
  src: url("/wp-content/themes/steen/app/public/font/PlayfairDisplay-BlackItalic.eot");
  src: url("/wp-content/themes/steen/app/public/font/PlayfairDisplay-BlackItalic.ttf") format("truetype"), url("/wp-content/themes/steen/app/public/font/PlayfairDisplay-BlackItalic.eot?#iefix") format("embedded-opentype"), url("/wp-content/themes/steen/app/public/font/PlayfairDisplay-BlackItalic.woff") format("woff"), url("/wp-content/themes/steen/app/public/font/PlayfairDisplay-BlackItalic.svg#ywftsvg") format("svg");
  font-weight: 900;
  font-style: italic; }

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px 0;
  z-index: 10; }
  .header__inner {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: justify;
            justify-content: space-between; }
  .header .logo {
    color: #DFB1A7; }
    .header .logo .img {
      width: 180px;
      height: auto; }
  .header .main-menu {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    margin: 0;
    padding: 0 0 4px 0;
    background: #111111;
    box-shadow: 0px -1px 16px rgba(0, 0, 0, 0.08); }
    .header .main-menu__inner {
      height: 56px;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: end;
              align-items: flex-end; }
    .header .main-menu .item {
      flex-basis: 0;
      -webkit-box-flex: 1;
              flex-grow: 1;
      max-width: 100%;
      text-align: center; }
      .header .main-menu .item__link {
        color: #ffffff;
        display: inline-block; }
      .header .main-menu .item__icon {
        color: #ffffff;
        display: inline-block; }
      .header .main-menu .item__text {
        font-size: 11px;
        line-height: 15px;
        display: block;
        margin-top: 4px; }
      .header .main-menu .item.active .item__link {
        color: #DFB1A7;
        opacity: 1; }
      .header .main-menu .item.active .item__text {
        font-weight: bold; }
      .header .main-menu .item.active .item__icon {
        color: #DFB1A7; }
    .header .main-menu--ios {
      padding-bottom: 10px; }
  .header .hamburger {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    right: 0;
    background: none;
    border: none;
    width: 24px;
    height: 22px;
    display: none; }
    .header .hamburger .line {
      width: 24px;
      height: 2px;
      display: inline-block;
      position: absolute;
      left: 0;
      background-color: #ffffff; }
      .header .hamburger .line:nth-child(1) {
        top: 0; }
      .header .hamburger .line:nth-child(2) {
        top: 10px; }
      .header .hamburger .line:nth-child(3) {
        top: 20px; }
    .header .hamburger--clicked {
      top: 0;
      -webkit-transform: none;
              transform: none;
      width: 50px;
      height: 50px; }
      .header .hamburger--clicked svg {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
  .header .search-suggest {
    display: none; }
  .header--filter {
    padding: 0; }
    .header--filter .logo,
    .header--filter .search--inside-header {
      display: none; }
  .header--bg-dark {
    background-color: #131418; }
  .header--none-search .search__mask {
    display: none; }
  .header--home .search__mask {
    display: none; }
  .header.search-active .main-menu .item.active .item__link {
    color: #212121;
    opacity: 0.4; }
  .header.search-active .main-menu .item.active .item__text {
    font-weight: normal; }
  .header.search-active .main-menu .item--search .item__link {
    color: #242d33;
    opacity: 1; }
  .header.search-active .main-menu .item--search .item__icon {
    color: #242d33; }
  .header.search-active .main-menu .item--search .item__text {
    font-weight: bold; }
  .header--sticky.header--home .search__mask {
    display: block; }
  @media (min-width: 768px) {
    .header {
      height: 89px;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
              align-items: center; }
      .header__inner {
        -webkit-box-pack: justify;
                justify-content: space-between;
        padding-right: 50px;
        position: relative; }
      .header .main-menu {
        top: 0;
        bottom: auto;
        height: 100%;
        display: none;
        background-color: rgba(0, 0, 0, 0.8);
        padding-top: 23px; }
        .header .main-menu__inner {
          max-width: 690px;
          margin: 0 auto;
          display: block;
          position: relative;
          padding-top: 10px; }
        .header .main-menu .item {
          width: 100%;
          text-align: left;
          margin-top: 20px; }
          .header .main-menu .item:first-child {
            margin-top: 0; }
          .header .main-menu .item__icon {
            display: none; }
          .header .main-menu .item__link {
            font-family: "PlayfairDisplay";
            padding: 0;
            color: #000000;
            opacity: 1; }
          .header .main-menu .item__text {
            font-size: 17px;
            line-height: 21px;
            letter-spacing: 0.1em;
            color: #ffffff;
            font-family: "Montserrat";
            text-transform: uppercase; }
          .header .main-menu .item.active .item__text {
            font-weight: bold; }
        .header .main-menu--ios {
          padding-bottom: 4px; }
      .header .logo {
        position: relative;
        top: 7px;
        color: #DFB1A7; }
        .header .logo .img {
          width: 154px;
          height: auto; }
      .header .hamburger--clicked {
        display: block;
        color: #ffffff; }
        .header .hamburger--clicked svg {
          left: auto;
          -webkit-transform: translateY(-50%);
                  transform: translateY(-50%);
          right: 0; }
      .header .search-suggest {
        display: block;
        margin-bottom: 72px; }
        .header .search-suggest .input-group {
          margin-bottom: 2px;
          border: 1px solid #e1e1e1;
          border-radius: 23px;
          padding-right: 18px;
          padding-left: 14px;
          background-color: #ffffff;
          height: 49px;
          width: 100%;
          max-width: 553px; }
        .header .search-suggest .input-group-prepend {
          width: 22px; }
        .header .search-suggest .input-group-icon {
          width: 17px;
          height: auto; }
        .header .search-suggest .form-control {
          border: none;
          border-radius: 0;
          background-color: transparent;
          height: 100%;
          font-size: 15px;
          line-height: 18px;
          color: #000000; }
        .header .search-suggest .input-clear {
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
          text-align: right;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          color: #000000;
          border: none;
          background: none;
          padding: 0;
          display: none; }
        .header .search-suggest .list {
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          letter-spacing: 0.1em;
          font-family: "Montserrat";
          padding-left: 33px;
          max-width: 553px; }
          .header .search-suggest .list .item {
            border-bottom: 0.5px solid rgba(255, 255, 255, 0.16);
            padding-bottom: 17px;
            padding-top: 12px;
            margin-top: 0; }
            .header .search-suggest .list .item__link {
              font-family: "Montserrat"; }
            .header .search-suggest .list .item .icon {
              display: inline-block;
              width: 14px;
              height: auto;
              margin-right: 10px;
              color: #ffffff; }
            .header .search-suggest .list .item a {
              color: #ffffff; }
      .header--filter {
        padding: 0;
        background-color: #131418; }
        .header--filter .logo,
        .header--filter .search--inside-header {
          display: block; } }
  @media (min-width: 991px) {
    .header {
      height: 81px; }
      .header__inner {
        padding-right: 0; }
      .header .logo {
        top: 0; }
        .header .logo .img {
          width: 205px;
          height: auto; }
      .header .main-menu {
        position: absolute;
        height: auto;
        display: block;
        width: auto;
        background-color: transparent;
        left: auto;
        right: 0;
        top: -8px;
        box-shadow: none;
        padding-top: 0; }
        .header .main-menu .item {
          width: auto;
          display: inline-block;
          margin: 0 0 0 32px; }
          .header .main-menu .item__text {
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.1em;
            font-family: "Montserrat";
            color: #fafafa;
            font-weight: 500; }
          .header .main-menu .item__link {
            color: #fafafa;
            padding: 0; }
            .header .main-menu .item__link:hover {
              font-weight: bold; }
              .header .main-menu .item__link:hover .item__text {
                font-weight: bold; }
        .header .main-menu .hamburger {
          display: none; }
        .header .main-menu--ios {
          padding-bottom: 4px; }
      .header .search-suggest {
        display: none; }
      .header--filter {
        background-color: #131418;
        padding: 0; }
        .header--filter .logo,
        .header--filter .search--inside-header {
          display: block; }
      .header--child-page, .header--filter, .header--sticky {
        background-color: #131418;
        padding: 0; }
        .header--child-page .logo, .header--filter .logo, .header--sticky .logo {
          top: 9px; }
        .header--child-page .header__inner, .header--filter .header__inner, .header--sticky .header__inner {
          height: 81px;
          -webkit-box-align: center;
                  align-items: center; }
        .header--child-page .main-menu, .header--filter .main-menu, .header--sticky .main-menu {
          position: relative;
          top: auto;
          right: auto;
          padding: 0; }
          .header--child-page .main-menu__inner, .header--filter .main-menu__inner, .header--sticky .main-menu__inner {
            height: auto; }
          .header--child-page .main-menu .item, .header--filter .main-menu .item, .header--sticky .main-menu .item {
            width: auto;
            display: inline-block;
            margin: 0 0 0 32px; }
          .header--child-page .main-menu--ios, .header--filter .main-menu--ios, .header--sticky .main-menu--ios {
            padding: 0; }
        .header--child-page .search--inside-header, .header--filter .search--inside-header, .header--sticky .search--inside-header {
          max-width: unset;
          -webkit-box-flex: 1;
                  flex: 1;
          padding-right: 60px;
          padding-left: 49px; }
          .header--child-page .search--inside-header .search__mask .input-group, .header--filter .search--inside-header .search__mask .input-group, .header--sticky .search--inside-header .search__mask .input-group {
            height: 47px;
            border-radius: 24px; }
      .header--about .main-menu {
        top: 50px; }
      .header--sticky.header--about .main-menu {
        top: auto; } }

body {
  padding-bottom: 56px; }
  body.no-padding {
    padding-bottom: 0; }
  @media (min-width: 768px) {
    body {
      padding-bottom: 0; } }

.footer {
  background-color: #000000;
  padding: 34px 0 0 0;
  color: #ffffff;
  font-size: 18px;
  line-height: 25px; }
  .footer .desc {
    font-weight: 500;
    margin-bottom: 25px; }
  .footer p:empty {
    margin: 0; }
  .footer .logo {
    display: inline-block;
    color: #DFB1A7;
    margin-bottom: 10px; }
    .footer .logo svg,
    .footer .logo .img {
      width: 128px; }
  .footer .col {
    flex-basis: unset;
    -webkit-box-flex: unset;
            flex-grow: unset; }
    .footer .col--info {
      width: 100%; }
    .footer .col--links {
      display: none; }
  .footer .input-group {
    border: 2px solid #ffffff;
    height: 46px; }
    .footer .input-group .form-control {
      border: none;
      outline: none;
      background-color: transparent;
      border-radius: 0;
      text-transform: uppercase;
      padding: 0 14px;
      height: 100%;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 1.05px;
      font-family: "Montserrat";
      height: 100%;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
              align-items: center;
      color: #ffffff; }
      .footer .input-group .form-control::-webkit-input-placeholder {
        color: #ffffff; }
      .footer .input-group .form-control:-ms-input-placeholder {
        color: #ffffff; }
      .footer .input-group .form-control::-moz-placeholder {
        color: #ffffff; }
      .footer .input-group .form-control::-ms-input-placeholder {
        color: #ffffff; }
      .footer .input-group .form-control::placeholder {
        color: #ffffff; }
    .footer .input-group .input-group-prepend {
      width: 56px;
      background-color: #ffffff;
      color: #000000;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
              align-items: center;
      -webkit-box-pack: center;
              justify-content: center; }
  .footer .contact-info {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin-top: 9px;
    padding-bottom: 14px; }
  .footer .copy {
    width: 100%;
    margin: 0;
    text-align: center;
    overflow: hidden; }
  .footer .social {
    width: 100%;
    -webkit-box-ordinal-group: 0;
            order: -1;
    text-align: center;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: center;
            justify-content: center; }
    .footer .social .item {
      vertical-align: middle;
      margin-top: 20px;
      padding: 0 24px; }
  @media (min-width: 768px) {
    .footer {
      padding: 47px 0 0 0; }
      .footer .logo {
        margin-bottom: 12px; }
      .footer .desc {
        margin-bottom: 29px; }
      .footer .col--info {
        width: 225px; }
      .footer .col--links {
        display: -webkit-box;
        display: flex;
        -webkit-box-flex: 1;
                flex: 1;
        padding-left: 16px; }
      .footer .listlinks {
        font-size: 14px;
        line-height: 18px;
        margin-left: 65px; }
        .footer .listlinks__title {
          font-weight: bold;
          color: #ffffff; }
        .footer .listlinks .item {
          margin-top: 11px; }
          .footer .listlinks .item:first-child {
            margin-top: 17px; }
          .footer .listlinks .item__link {
            color: #ffffff; }
      .footer .contact-info {
        border-top: 1px solid rgba(255, 255, 255, 0.4);
        position: relative;
        padding-top: 21px;
        padding-bottom: 17px;
        -webkit-box-align: center;
                align-items: center;
        -webkit-box-pack: justify;
                justify-content: space-between;
        margin-top: 33px; }
      .footer .copy {
        display: inline-block;
        width: auto;
        font-size: 13px;
        line-height: 15px; }
      .footer .social {
        width: auto;
        -webkit-box-ordinal-group: unset;
                order: unset;
        display: inline-block;
        margin-bottom: 0; }
        .footer .social .item {
          margin-top: 0;
          padding: 0;
          display: inline-block;
          margin: 0 12px; }
      .footer .term-policy {
        margin-bottom: 0;
        font-weight: 500;
        font-size: 13px;
        line-height: 15px; }
        .footer .term-policy .item {
          display: inline-block; }
          .footer .term-policy .item a {
            color: #ffffff; } }
  @media (min-width: 991px) {
    .footer {
      padding: 73px 0 0 0; }
      .footer .container {
        position: relative; }
      .footer .back-to-top {
        border-radius: 50%;
        width: 60px;
        height: 60px;
        border: 1px solid #ffffff;
        position: absolute;
        bottom: 0;
        right: 15px;
        background: none;
        outline: none;
        bottom: 130px;
        right: 9px;
        color: #ffffff;
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
                align-items: center;
        -webkit-box-pack: center;
                justify-content: center;
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg); }
        .footer .back-to-top:hover {
          background-color: #ffffff;
          color: #000000; }
        .footer .back-to-top .icon {
          width: 17px;
          height: auto; }
      .footer .logo {
        margin-bottom: 22px; }
        .footer .logo svg,
        .footer .logo .img {
          width: 200px;
          height: auto; }
      .footer .contact-info {
        padding-top: 32px;
        padding-bottom: 40px;
        margin-top: 55px; }
      .footer .copy,
      .footer .term-policy {
        font-size: 14px;
        line-height: 17px; }
      .footer .col--info {
        width: 342px; }
      .footer .col--links {
        padding-top: 77px;
        padding-left: 28px; }
      .footer .desc {
        font-size: 14px;
        line-height: 19px;
        margin-bottom: 49px; }
      .footer .listlinks {
        font-size: 14px;
        line-height: 20px;
        margin-left: 85px; }
        .footer .listlinks .item {
          margin-top: 12px; }
          .footer .listlinks .item:first-child {
            margin-top: 23px; } }

.search__form {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  display: none;
  overflow: hidden; }
  .search__form .input-group {
    margin-bottom: 35px;
    height: 46px;
    border: 1px solid #b9b9b9;
    border-radius: 23px;
    position: absolute;
    top: 44px;
    left: 16px;
    width: calc(100% - 32px - 40px);
    padding-right: 18px;
    padding-left: 18px;
    background-color: #b9b9b9; }
  .search__form .input-group-prepend {
    width: 27px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    margin-right: 5px; }
  .search__form .input-group-icon {
    width: 22px;
    height: auto; }
  .search__form .form-control {
    border: none;
    border-radius: 0;
    background-color: transparent;
    height: 100%;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    color: #000000;
    padding: 0;
    -webkit-transition: none;
    transition: none; }
  .search__form .input-clear {
    font-weight: 500;
    font-size: 8px;
    line-height: 20px;
    text-align: right;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #000000;
    border: none;
    background: none;
    padding: 0;
    display: none; }

.search__bg {
  position: absolute;
  bottom: 0;
  right: 0;
  display: none; }

.search__close {
  width: 39px;
  height: 39px;
  background: none;
  color: #ffffff;
  border: none;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  position: absolute;
  top: 49px;
  right: 10px;
  z-index: 10;
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 50%; }

.search__mask .input-group {
  background-color: #ffffff;
  height: 36px;
  border-radius: 18px;
  overflow: hidden;
  padding-right: 10px; }

.search__mask .form-control,
.search__mask .input-group-icon {
  color: #000000; }

.search__mask .input-group-prepend {
  width: 32px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center; }

.search__mask .form-control {
  font-size: 12px;
  line-height: 14px;
  border: none;
  outline: none;
  border-radius: 0;
  height: 36px;
  padding: 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-transition: none;
  transition: none; }
  .search__mask .form-control::-webkit-input-placeholder {
    color: #000000; }
  .search__mask .form-control:-ms-input-placeholder {
    color: #000000; }
  .search__mask .form-control::-moz-placeholder {
    color: #000000; }
  .search__mask .form-control::-ms-input-placeholder {
    color: #000000; }
  .search__mask .form-control::placeholder {
    color: #000000; }

.search__step {
  position: absolute;
  right: 0;
  width: 100%;
  height: 100%;
  height: 100%;
  padding: 115px 16px 56px 16px;
  background-color: black;
  -webkit-transition: right .3s ease-in-out;
  transition: right .3s ease-in-out; }
  .search__step.move-left {
    right: 100%; }
  .search__step--child {
    right: -100%;
    z-index: 5; }
    .search__step--child.active {
      right: 0; }

.search__back {
  margin-right: 15px; }

.search__selected {
  font-size: 27px;
  line-height: 40px;
  color: #ffffff;
  opacity: 0.9;
  font-family: "PlayfairDisplay";
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
  position: absolute;
  top: 43px;
  left: 16px; }

.search__back {
  background: none;
  border: none;
  display: inline-block;
  vertical-align: middle; }

.search__label {
  display: inline-block;
  vertical-align: middle; }

.search .ele-animate {
  position: relative;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out; }
  .search .ele-animate.animated {
    opacity: 1;
    top: 0; }

.search .results {
  overflow-y: auto;
  position: relative;
  height: 100%;
  z-index: 1; }
  .search .results__title {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #ffffff;
    padding: 8px 0 17px 0; }
  .search .results__back {
    color: #000000;
    display: inline-block;
    position: relative;
    top: auto;
    right: auto;
    padding: 0;
    background: none;
    border: none;
    margin: 0;
    display: none;
    width: 30px; }
    .search .results__back .icon {
      width: 7px;
      height: auto; }

.search .list {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 0; }
  .search .list .item {
    padding-top: 12px;
    padding-bottom: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.16);
    cursor: pointer; }
    .search .list .item .icon {
      display: none; }
    .search .list .item a {
      color: #ffffff; }

.search .loading {
  margin: 0 auto; }

.search--inside-header {
  -webkit-box-flex: 1;
          flex: 1;
  padding-left: 20px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: relative;
  top: 0;
  left: 0;
  max-width: 160px; }

@media (min-width: 768px) {
  .search--inside-header {
    max-width: 275px; }
  .search__mask .input-group {
    border-radius: 21px; }
  .search__mask .input-group,
  .search__mask .form-control {
    height: 41px; }
  .search__mask .input-group-prepend {
    width: 42px; }
    .search__mask .input-group-prepend svg {
      width: 15px;
      height: auto; }
  .search__mask .form-control {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px; }
    .search__mask .form-control .text {
      padding: 0 8px;
      position: relative; }
      .search__mask .form-control .text::before {
        content: '';
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: #000000;
        position: absolute;
        top: 7px;
        left: 0; }
      .search__mask .form-control .text:first-child {
        padding-left: 0; }
        .search__mask .form-control .text:first-child::before {
          display: none; }
  .search__form {
    padding: 0 30px;
    background-color: black; }
  .search .results {
    margin-top: 77px;
    height: auto;
    max-height: calc(100% - 77px - 23px);
    background-color: transparent;
    padding: 3px 26px 0 3px; }
    .search .results__title {
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.1em;
      color: #ffffff;
      border-bottom: 0.5px solid rgba(255, 255, 255, 0.16);
      padding: 19px 0 19px 0; }
    .search .results__back {
      display: inline-block;
      color: #ffffff; }
  .search .list {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    color: #FEFEFE;
    font-weight: 500;
    font-size: 9px;
    line-height: 20px;
    letter-spacing: 0.15em;
    padding-top: 20px;
    padding-bottom: 26px;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-wrap: wrap;
    padding-left: 21px; }
    .search .list .item {
      width: 25%;
      border-top: none;
      min-height: 38px;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
              align-items: center;
      padding: 0; }
      .search .list .item--suggest {
        width: 100%;
        min-height: 49px;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        border-top: 0.5px solid rgba(0, 0, 0, 0.16); }
        .search .list .item--suggest:first-child {
          border-top: 0; }
        .search .list .item--suggest .icon {
          display: inline-block;
          margin-right: 10px; }
      .search .list .item--full-width {
        width: 100%; }
      .search .list .item a {
        color: #ffffff; }
  .search .loading {
    margin-top: 20px; }
  .search__step {
    padding: 0;
    right: auto;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    max-width: 690px;
    background-color: transparent; }
    .search__step.move-left .results {
      display: none; }
    .search__step--child {
      top: 77px;
      max-height: calc(100% - 77px);
      display: none; }
      .search__step--child .results {
        margin-top: 0; }
      .search__step--child.active {
        display: block;
        z-index: 1; }
      .search__step--child .list {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
                flex-direction: row; }
    .search__step .input-group {
      top: 23px;
      left: auto;
      height: 41px;
      width: calc(100% - 46px);
      right: 46px;
      max-width: 647px; }
    .search__step .input-group-icon {
      width: 19px; }
    .search__step .input-group-prepend {
      width: 31px; }
  .search__selected {
    display: none; }
  .search__close {
    top: 26px;
    right: 0;
    position: absolute;
    width: 33px;
    height: 33px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: center;
            justify-content: center;
    border: 1px solid #ffffff;
    background: none;
    border-radius: 50%; }
    .search__close:hover {
      background-color: #ffffff;
      color: #000000; } }

@media (min-width: 991px) {
  .search__form {
    z-index: 11; }
  .search__step {
    max-width: 1200px;
    margin: 0; }
  .search--inside-header {
    -webkit-box-flex: unset;
            flex: unset;
    width: 300px; }
    .search--inside-header .search__mask .input-group,
    .search--inside-header .search__mask .form-control {
      height: 43px; }
    .search--inside-header .search__mask .form-control {
      font-size: 13px;
      line-height: 16px;
      font-weight: normal; }
    .search--inside-header .search__mask .input-group {
      padding-right: 20px; }
    .search--inside-header .search__mask .input-group-prepend {
      -webkit-box-pack: start;
              justify-content: flex-start;
      padding-left: 16px; }
  .search .results {
    width: 696px;
    max-width: unset;
    float: right; } }

@media (min-width: 991px) {
  .header--child-page .search .results, .header--filter .search .results, .header--sticky .search .results {
    width: calc(100% - 212px); }
  .header--child-page .search__step .input-group, .header--filter .search__step .input-group, .header--sticky .search__step .input-group {
    left: 212px;
    right: auto;
    max-width: calc(100% - 212px - 46px);
    top: 18px;
    height: 47px; } }

.banner {
  position: relative; }
  .banner__image {
    width: 100%;
    height: 507px;
    -o-object-fit: cover;
       object-fit: cover; }
  .banner__caption {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.12)), to(rgba(0, 0, 0, 0.53)));
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0.53) 100%);
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: center;
            justify-content: center; }
  .banner__title {
    width: 271px;
    height: auto;
    margin-bottom: 9px; }
  .banner__text_title {
    font-family: "PlayfairDisplay";
    font-style: italic;
    font-weight: normal;
    font-size: 35px;
    line-height: 115%;
    color: #ffffff; }
  .banner__desc {
    font-size: 19px;
    line-height: 26px;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 0;
    text-align: center; }
  .banner .input-group {
    background-color: #ffffff;
    height: 40px;
    border-radius: 20px;
    overflow: hidden;
    padding-right: 10px;
    margin-top: 27px;
    max-width: 368px; }
  .banner .form-control,
  .banner .input-group-icon {
    color: #000000; }
  .banner .input-group-prepend {
    width: 41px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: center;
            justify-content: center; }
  .banner .form-control {
    font-size: 12px;
    line-height: 14px;
    border: none;
    outline: none;
    border-radius: 0;
    height: 40px;
    padding: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    -webkit-transition: none;
    transition: none; }
    .banner .form-control::-webkit-input-placeholder {
      color: #000000; }
    .banner .form-control:-ms-input-placeholder {
      color: #000000; }
    .banner .form-control::-moz-placeholder {
      color: #000000; }
    .banner .form-control::-ms-input-placeholder {
      color: #000000; }
    .banner .form-control::placeholder {
      color: #000000; }
  .banner--about-us .banner__caption {
    padding-bottom: 33px;
    background: none; }
    .banner--about-us .banner__caption::before {
      content: '';
      width: 100%;
      height: 100%;
      background: linear-gradient(180.68deg, rgba(0, 0, 0, 0.12) 0.37%, #000000 99.64%);
      opacity: 0.4;
      position: absolute;
      top: 0;
      left: 0; }
    .banner--about-us .banner__caption .container {
      position: relative;
      z-index: 1; }
  .banner--about-us .banner__title {
    font-family: "PlayfairDisplay";
    font-style: italic;
    font-weight: normal;
    font-size: 28px;
    line-height: 115%;
    color: #ffffff;
    width: unset; }
  .banner--home .banner__image {
    height: calc(100vh - 100px); }
  @media (min-width: 768px) {
    .banner__title {
      width: 354px; }
    .banner__desc {
      max-width: 500px; }
    .banner--home .banner__image {
      height: 100vh;
      min-height: 509px; }
    .banner--about-us .banner__title {
      margin-bottom: 7px; }
    .banner--about-us .banner__caption {
      margin-bottom: 31px; }
      .banner--about-us .banner__caption:before {
        background: linear-gradient(181.43deg, rgba(0, 0, 0, 0.32) -49.74%, rgba(0, 0, 0, 0.36) 97.41%);
        opacity: 1; } }
  @media (min-width: 991px) {
    .banner__title {
      width: 440px; }
    .banner__desc {
      font-size: 16px;
      letter-spacing: 0.64px; }
    .banner__caption {
      background-color: rgba(0, 0, 0, 0.3); }
    .banner--home .banner__image {
      min-height: 659px; }
    .banner--home .banner__caption {
      background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.01)), to(rgba(0, 0, 0, 0.6)));
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.01) 0%, rgba(0, 0, 0, 0.6) 100%); }
    .banner--home .banner__title {
      margin-bottom: 22px; }
    .banner--about-us {
      margin-top: 81px; }
      .banner--about-us .banner__caption:before {
        background: linear-gradient(181.43deg, rgba(0, 0, 0, 0.32) -49.74%, rgba(0, 0, 0, 0.36) 97.41%); }
      .banner--about-us .banner__title {
        font-size: 60px;
        line-height: 80px;
        letter-spacing: 0.05em;
        margin-bottom: 16px; }
      .banner--about-us .banner__desc {
        font-weight: 500;
        font-size: 18px;
        line-height: 25px; }
      .banner--about-us .banner__caption {
        padding-bottom: 58px; }
    .banner .input-group {
      margin-top: 0;
      box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1);
      height: 43px;
      border-radius: 23px;
      margin-top: 25px; }
    .banner .form-control {
      height: 43px;
      font-size: 13px;
      line-height: 16px; }
    .banner .input-group-icon {
      width: 17px;
      height: auto; }
    .banner .input-group-prepend {
      width: 44px; } }

.intro {
  background-color: #242D33;
  padding: 51px 0 52px 0; }
  .intro .item {
    color: #E6E6E6;
    margin-top: 30px; }
    .intro .item:first-child {
      margin-top: 0; }
    .intro .item__title {
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #E6E6E6;
      font-weight: bold;
      margin-bottom: 10px;
      font-size: 18px;
      line-height: 21px; }
    .intro .item__desc {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.01em;
      margin-bottom: 0; }
      .intro .item__desc div {
        display: inline; }
  @media (min-width: 768px) {
    .intro {
      padding: 66px 0 45px 0; }
      .intro__inner {
        display: -webkit-box;
        display: flex;
        margin: 0 -1.21429em; }
      .intro .item {
        margin-top: 0;
        width: 33.33333%;
        padding: 0 1.21429em; }
        .intro .item__title {
          font-size: 15px;
          line-height: 18px;
          letter-spacing: 0.05em;
          margin-bottom: 7px; } }
  @media (min-width: 991px) {
    .intro {
      padding: 72px 0 59px 0; }
      .intro .container {
        max-width: 70%;
        padding: 0; }
      .intro__inner {
        margin: 0; }
      .intro .item {
        padding: 0 25px; }
        .intro .item__title {
          font-size: 17px;
          line-height: 17.2px;
          margin-bottom: 15px; }
        .intro .item__desc {
          font-size: 16px;
          line-height: 20px;
          text-align: left; } }

.recommended-place {
  background-color: #242D33; }
  .recommended-place .container {
    padding: 0;
    max-width: unset; }
  .recommended-place .item {
    width: 256px;
    padding-left: 15px;
    font-size: 14px; }
    .recommended-place .item:focus {
      outline: none; }
    .recommended-place .item__inner {
      position: relative;
      color: #ffffff; }
    .recommended-place .item__image {
      width: 100%;
      position: relative;
      line-height: 0; }
      .recommended-place .item__image .img-mask {
        width: 100%;
        opacity: 0; }
      .recommended-place .item__image .img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        -o-object-fit: cover;
           object-fit: cover; }
    .recommended-place .item__badge {
      text-transform: uppercase;
      color: #ffffff;
      position: absolute;
      font-size: 0.71429em;
      line-height: 1.2em;
      top: 1.4em;
      left: 1.3em;
      z-index: 6;
      letter-spacing: 0.3em; }
    .recommended-place .item__ranking {
      background-color: #131418;
      font-weight: bold;
      font-size: 1.14286em;
      line-height: 1.3125em;
      text-align: center;
      color: #d1d1d1;
      font-family: "PlayfairDisplay";
      width: 1.75em;
      height: 1.9375em;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 5;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
              align-items: center;
      -webkit-box-pack: center;
              justify-content: center; }
    .recommended-place .item__number {
      display: inline-block;
      position: relative; }
    .recommended-place .item__hash {
      display: inline-block;
      position: absolute;
      top: -0.57143em;
      left: -0.35714em; }
    .recommended-place .item__caption {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      width: 100%;
      height: 100%;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: end;
              align-items: flex-end;
      padding: 0.42857em 1em; }
      .recommended-place .item__caption__inner {
        position: absolute;
        z-index: 5;
        bottom: 10px; }
      .recommended-place .item__caption:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(359.44deg, rgba(0, 0, 0, 0.7) 23.12%, rgba(0, 0, 0, 0) 99.46%);
        opacity: .7; }
    .recommended-place .item__title {
      font-style: italic;
      font-weight: bold;
      font-size: 1.14286em;
      line-height: 1.3125em;
      font-family: "PlayfairDisplay";
      margin-bottom: 0.3125em; }
    .recommended-place .item__address {
      font-size: 0.85714em;
      line-height: 1.25em;
      margin-bottom: 0em; }
    .recommended-place .item__intro {
      font-size: 0.85714em;
      line-height: 1.25em;
      letter-spacing: 0.05em;
      margin-bottom: 0;
      display: none;
      font-weight: 600; }
    .recommended-place .item__link {
      display: block;
      position: absolute;
      z-index: 6;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0; }
    .recommended-place .item__view {
      display: none; }
  @media (min-width: 768px) {
    .recommended-place .item__caption {
      padding: 0.42857em 1em 1.14286em 1em; }
      .recommended-place .item__caption::before {
        background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.77)), to(rgba(0, 0, 0, 0)));
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.77) 0%, rgba(0, 0, 0, 0) 100%); }
    .recommended-place .item__badge {
      background-color: #000000;
      padding: 1.125em 2.125em 1em 2.125em;
      top: 0;
      left: 0; }
    .recommended-place .item__intro {
      display: block; }
    .recommended-place .item__title {
      margin-bottom: 0.3125em; } }
  @media (min-width: 1201px) {
    .recommended-place .container {
      max-width: unset; }
    .recommended-place__slider {
      display: -webkit-box;
      display: flex;
      flex-wrap: wrap; }
    .recommended-place .item {
      width: 20%;
      padding: 0; }
      .recommended-place .item:hover .item__caption {
        opacity: 1; }
      .recommended-place .item__caption {
        opacity: 0;
        -webkit-transition: opacity 0.3s ease;
        transition: opacity 0.3s ease;
        display: block;
        padding: 1.25em 0.625em 0 1.25em; }
        .recommended-place .item__caption::before {
          background: none;
          background-color: #000000; }
      .recommended-place .item__badge {
        position: relative;
        top: auto;
        left: auto;
        background-color: transparent;
        font-weight: 300;
        font-size: 0.71429em;
        line-height: 1.2em;
        letter-spacing: 0.52em;
        padding: 0;
        margin-bottom: 1em; }
      .recommended-place .item__title {
        font-size: 1.85714em;
        line-height: 1.11538em;
        margin-bottom: 0.19231em; }
      .recommended-place .item__address {
        font-weight: 200;
        font-size: 1em;
        line-height: 1.21429em;
        letter-spacing: 0.12em;
        margin-bottom: 0; }
      .recommended-place .item__intro {
        display: block;
        font-weight: 600;
        font-size: 1em;
        line-height: 1.21429em;
        letter-spacing: 0.05em;
        margin-bottom: 1.64286em; }
      .recommended-place .item__view {
        text-transform: uppercase;
        border: 1px solid #ffffff;
        width: 144px;
        height: 43px;
        font-size: 11px;
        line-height: 13px;
        text-align: center;
        letter-spacing: 0.4em;
        display: -webkit-inline-box;
        display: inline-flex; }
      .recommended-place .item__badge {
        font-weight: 500;
        letter-spacing: 0.40em; }
      .recommended-place .item__title {
        font-size: 18px;
        line-height: 25px; }
      .recommended-place .item__address {
        font-size: 12px; }
      .recommended-place .item__intro {
        font-size: 11px; }
      .recommended-place .item__view {
        font-size: 10px; } }
  @media (min-width: 1300px) {
    .recommended-place .item {
      font-size: 13px; }
      .recommended-place .item__image {
        min-height: 289px; } }
  @media (min-width: 1500px) {
    .recommended-place .item {
      font-size: 14px; } }
  @media (min-width: 1600px) {
    .recommended-place .item {
      font-size: 15px; } }

.top-cities {
  background-color: #242D33;
  padding-top: 55px;
  position: relative; }
  .top-cities .container {
    padding: 0;
    position: relative;
    z-index: 1; }
  .top-cities__bg {
    position: absolute;
    top: 100px;
    right: 0; }
  .top-cities__title {
    color: #ffffff;
    padding: 0 15px;
    margin-bottom: 12px; }
  .top-cities__desc {
    margin-bottom: 32px;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #ffffff;
    padding: 0 15px; }
  .top-cities__show-all {
    display: none; }
  .top-cities .item {
    width: 271px;
    padding-left: 15px; }
    .top-cities .item:focus {
      outline: none; }
    .top-cities .item__inner {
      position: relative;
      color: #ffffff; }
    .top-cities .item__image {
      width: 100%;
      line-height: 0;
      -o-object-fit: cover;
         object-fit: cover;
      height: 198px; }
    .top-cities .item__image_top {
      width: 100%;
      line-height: 0;
      -o-object-fit: cover;
         object-fit: cover;
      height: 112px; }
    .top-cities .item__title {
      margin-bottom: 0;
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.03em;
      color: #ffffff; }
    .top-cities .item__caption {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      width: 100%;
      height: 100%;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: end;
              align-items: flex-end;
      padding: 16px; }
      .top-cities .item__caption__inner {
        position: relative;
        z-index: 5; }
      .top-cities .item__caption::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
        width: 100%;
        height: 100%;
        background: linear-gradient(0.43deg, rgba(0, 0, 0, 0.56) 0.5%, rgba(0, 0, 0, 0) 99.54%); }
    .top-cities .item__link {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 6;
      width: 100%;
      height: 100%;
      opacity: 0; }
  .top-cities--grid {
    background-color: #ffffff;
    padding-bottom: 39px;
    padding-top: 109px; }
    .top-cities--grid .top-cities__title {
      color: #000000;
      margin-bottom: 14px; }
    .top-cities--grid .top-cities__list {
      margin: 0 -6px;
      display: -webkit-box;
      display: flex;
      flex-wrap: wrap; }
    .top-cities--grid .item {
      width: 50%;
      padding: 0 6px;
      margin-top: 12px; }
      .top-cities--grid .item__title {
        font-weight: 600;
        font-size: 11px;
        line-height: 20px;
        letter-spacing: 0.25em;
        text-transform: uppercase;
        color: #ffffff; }
      .top-cities--grid .item__caption {
        padding-left: 12px;
        padding-bottom: 5px; }
        .top-cities--grid .item__caption::before {
          background: linear-gradient(358.26deg, #000000 20.39%, rgba(0, 0, 0, 0) 96.57%);
          opacity: 0.6; }
    .top-cities--grid .container {
      padding: 0 16px; }
  @media (min-width: 768px) {
    .top-cities {
      padding-top: 69px; }
      .top-cities .container {
        max-width: unset; }
      .top-cities__title {
        margin-bottom: 24px; }
      .top-cities__desc {
        margin-bottom: 31px; }
      .top-cities__bg {
        left: 0;
        right: auto; }
      .top-cities--grid {
        padding-top: 122px;
        padding-bottom: 66px; }
        .top-cities--grid .container {
          max-width: 720px; }
        .top-cities--grid .top-cities__list {
          overflow-x: scroll;
          overflow-y: hidden;
          white-space: nowrap;
          flex-wrap: unset;
          -webkit-overflow-scrolling: touch;
          margin: 0; }
          .top-cities--grid .top-cities__list::-webkit-scrollbar {
            display: none; }
        .top-cities--grid .top-cities__title {
          margin-bottom: 22px; }
        .top-cities--grid .item {
          display: inline-block;
          width: 256px;
          -webkit-box-flex: 0;
                  flex: 0 0 auto;
          margin-top: 0;
          padding: 0;
          margin-left: 16px; }
          .top-cities--grid .item:first-child {
            margin-left: 0; }
          .top-cities--grid .item__caption {
            -webkit-box-align: end;
                    align-items: flex-end;
            -webkit-box-pack: start;
                    justify-content: flex-start;
            padding-left: 16px;
            padding-bottom: 16px; }
            .top-cities--grid .item__caption:before {
              background: linear-gradient(0.43deg, rgba(0, 0, 0, 0.56) 0.5%, rgba(0, 0, 0, 0) 99.54%); }
          .top-cities--grid .item__title {
            font-size: 15px;
            line-height: 18px;
            letter-spacing: 0.03em; }
          .top-cities--grid .item__image {
            height: 198px; } }
  @media (min-width: 1025px) {
    .top-cities .container {
      padding: 0 15px;
      max-width: 1200px; }
    .top-cities__bg {
      left: auto;
      right: 0; }
    .top-cities__slider {
      display: -webkit-box;
      display: flex;
      flex-wrap: wrap;
      text-transform: uppercase; }
    .top-cities__desc {
      font-size: 14px;
      line-height: 18.39px;
      letter-spacing: 0.28px;
      color: #E6E6E6; }
    .top-cities__show-all {
      font-size: 8px;
      letter-spacing: 4.8px;
      color: #f2f2f2;
      border: 1px solid #DFB1A7;
      text-transform: uppercase;
      width: 174px;
      height: 50px;
      margin-top: 60px;
      display: -webkit-inline-box;
      display: inline-flex; }
      .top-cities__show-all:hover {
        background-color: #DFB1A7;
        color: #F2F2F2; }
    .top-cities .item {
      width: 20%;
      padding: 0; }
      .top-cities .item:hover .item__caption {
        opacity: 1; }
      .top-cities .item__caption {
        -webkit-box-pack: center;
                justify-content: center;
        -webkit-box-align: center;
                align-items: center;
        opacity: 0;
        -webkit-transition: opacity .3s ease;
        transition: opacity .3s ease;
        text-align: center; }
        .top-cities .item__caption::before {
          background-color: rgba(36, 45, 51, 0.85); }
      .top-cities .item__title {
        font-size: 14px;
        line-height: 55.61px;
        letter-spacing: 4.8px;
        color: #F2F2F2;
        text-align: center; }
      .top-cities .item__image {
        height: 240px; }
    .top-cities--grid {
      padding-top: 164px;
      padding-bottom: 80px; }
      .top-cities--grid .container {
        max-width: 1024px; }
      .top-cities--grid .top-cities__list {
        overflow: unset;
        white-space: unset;
        flex-wrap: wrap; }
      .top-cities--grid .top-cities__title {
        margin-bottom: 43px; }
      .top-cities--grid .item {
        white-space: unset;
        -webkit-box-flex: unset;
                flex: unset;
        margin-left: 0; }
        .top-cities--grid .item__image {
          height: 240px; } }
  @media (min-width: 1200px) {
    .top-cities .item__title {
      font-weight: 600;
      font-size: 12px;
      text-align: center;
      line-height: 20px;
      letter-spacing: 0.6em; }
    .top-cities--grid .container {
      max-width: 1200px; } }

.logos {
  background-color: #242D33;
  padding-top: 55px;
  padding-bottom: 27px; }
  .logos__title {
    padding: 0 15px;
    color: #ffffff;
    margin-bottom: 12px; }
  .logos__desc {
    padding: 0 15px;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 25px; }
  .logos__slider {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: center;
            justify-content: center; }
  .logos .container {
    padding: 0; }
  .logos .item {
    color: #B3B3B3;
    height: 25px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: end;
            align-items: flex-end;
    padding: 0 20px;
    margin-bottom: 27px; }
    .logos .item img {
      max-width: unset;
      height: 100%; }
    .logos .item:focus {
      outline: none; }
    .logos .item:first-child {
      padding-left: 15px; }
  @media (min-width: 768px) {
    .logos {
      padding-top: 68px;
      padding-bottom: 70px; }
      .logos__desc {
        font-size: 15px;
        line-height: 21px;
        margin-bottom: 19px; }
      .logos__title {
        margin-bottom: 23px; } }
  @media (min-width: 991px) {
    .logos {
      padding-top: 109px;
      padding-bottom: 109px; }
      .logos .item {
        margin: 0 30px;
        margin-top: 47px;
        display: inline-block; }
      .logos__title {
        margin-bottom: 13px; }
      .logos__desc {
        margin-bottom: 15px;
        color: #E6E6E6; }
      .logos__slider {
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: center;
                justify-content: center;
        flex-wrap: wrap;
        max-width: 850px;
        margin: 0 auto; } }

.number {
  background-color: #131418;
  color: #ffffff;
  padding-top: 29px;
  padding-bottom: 6px; }
  .number .col {
    text-align: center;
    width: 50%;
    flex-basis: unset; }
    .number .col:nth-child(1), .number .col:nth-child(2), .number .col:nth-child(3) {
      width: 33.33333%; }
  .number .item {
    margin-bottom: 25px; }
    .number .item__title {
      display: inline-block;
      position: relative;
      font-weight: 500;
      font-size: 34px;
      line-height: 37px;
      margin-bottom: 2px; }
    .number .item__plus {
      position: absolute;
      top: 8px;
      right: -9px; }
    .number .item__label {
      color: #F2F2F2;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.2em;
      margin-bottom: 0; }
  @media (min-width: 768px) {
    .number {
      padding-top: 66px;
      padding-bottom: 39px; }
      .number .row {
        -webkit-box-pack: center;
                justify-content: center; }
      .number .col {
        width: auto;
        -webkit-box-flex: unset;
                flex-grow: unset;
        padding: 0 30px; }
        .number .col:nth-child(1), .number .col:nth-child(2), .number .col:nth-child(3) {
          width: auto; }
      .number .item__title {
        font-weight: 500;
        font-size: 35px;
        line-height: 43px; }
      .number .item__label {
        font-size: 9px;
        line-height: 11px;
        text-align: center;
        letter-spacing: 0.2em; } }
  @media (min-width: 991px) {
    .number {
      padding-top: 44px;
      padding-bottom: 12px; }
      .number .row {
        -webkit-box-pack: center;
                justify-content: center; }
      .number .col {
        width: auto;
        -webkit-box-flex: unset;
                flex-grow: unset;
        padding: 0 45px; }
        .number .col:nth-child(1), .number .col:nth-child(2), .number .col:nth-child(3) {
          width: auto; }
      .number .item__title {
        font-size: 37px;
        letter-spacing: -1.48px;
        margin-bottom: 13px; }
      .number .item__label {
        font-size: 8px;
        letter-spacing: 4.8px;
        color: #f2f2f2; }
      .number .item__plus {
        position: absolute;
        top: -7px;
        right: -20px;
        width: 18px;
        height: auto; } }

.details {
  margin-top: 5em;
  position: relative;
  z-index: 1; }
  .details .container {
    padding: 0; }
  @media (min-width: 768px) {
    .details {
      position: relative;
      overflow: hidden;
      padding-top: 141px; }
      .details::before {
        content: '';
        width: 100%;
        height: 100%;
        top: -54px;
        left: 0;
        position: absolute;
        background-color: #131418; }
      .details__inner {
        display: -webkit-box;
        display: flex;
        position: relative;
        -webkit-box-align: center;
                align-items: center; } }
  @media (min-width: 991px) {
    .details {
      margin-top: 0; }
      .details::before {
        top: -49px; } }

.control-heading {
  position: absolute;
  top: 17px;
  left: 0;
  width: 100%;
  z-index: 9; }
  .control-heading__inner {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: justify;
            justify-content: space-between;
    padding: 0 16px; }
  .control-heading .back,
  .control-heading .share {
    color: #ffffff; }
  @media (min-width: 768px) {
    .control-heading {
      display: none; } }

.a2a_kit {
  display: none;
  opacity: 0;
  -webkit-transition: visibility 0s, opacity 0.5s linear;
  transition: visibility 0s, opacity 0.5s linear; }

.gallery-pictures .item {
  position: relative;
  outline: none; }
  .gallery-pictures .item:hover {
    outline: none; }
  .gallery-pictures .item::before, .gallery-pictures .item::after {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 1; }
  .gallery-pictures .item::before {
    height: 52.8125%;
    top: 0;
    background: -webkit-gradient(linear, left top, left bottom, from(#000000), to(rgba(0, 0, 0, 0)));
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    opacity: 0.3; }
  .gallery-pictures .item::after {
    height: 38.125%;
    bottom: 0;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(10.51%, #131418), color-stop(83.19%, rgba(0, 0, 0, 0)));
    background: linear-gradient(180deg, #131418 10.51%, rgba(0, 0, 0, 0) 83.19%);
    opacity: 0.6;
    -webkit-transform: matrix(1, 0, 0, -1, 0, 0);
            transform: matrix(1, 0, 0, -1, 0, 0); }
  .gallery-pictures .item__image {
    width: 100%;
    position: relative;
    overflow: hidden; }
    .gallery-pictures .item__image .img-mask {
      width: 100%;
      opacity: 0;
      visibility: hidden; }
    .gallery-pictures .item__image .img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
      position: absolute;
      top: 0;
      left: 0; }
  .gallery-pictures .item__caption {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 5;
    color: #ffffff;
    padding: 0 17px 10px 17px; }
  .gallery-pictures .item__location {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FAFAFA;
    margin-bottom: 0; }
    .gallery-pictures .item__location a {
      color: #ffffff; }
  .gallery-pictures .item__tag {
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.15em;
    color: #ffffff;
    margin-bottom: 0; }
    .gallery-pictures .item__tag .tag {
      display: inline-block;
      margin-right: 5px;
      margin-top: 3px; }
      .gallery-pictures .item__tag .tag a {
        color: #ffffff; }
        .gallery-pictures .item__tag .tag a:first-child {
          display: inline-block;
          margin-bottom: 4px; }
      .gallery-pictures .item__tag .tag:last-child {
        margin-right: 0; }

.gallery-pictures .slick-dots {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2px;
  position: absolute;
  bottom: 1px;
  left: 16px;
  width: calc(100% - 32px); }
  .gallery-pictures .slick-dots li {
    padding: 0 2px;
    -webkit-box-flex: 1;
            flex-grow: 1;
    max-width: 100%;
    height: 20px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center; }
    .gallery-pictures .slick-dots li.slick-active button {
      opacity: 1; }
    .gallery-pictures .slick-dots li button {
      width: 100%;
      max-width: 100%;
      height: 1px;
      overflow: hidden;
      border: none;
      background: none;
      background-color: #ffffff;
      opacity: 0.2;
      font-size: 0;
      -webkit-transition: opacity .3s ease;
      transition: opacity .3s ease; }

@media (min-width: 768px) {
  .gallery-pictures {
    width: 43.50282%; }
    .gallery-pictures .item__caption {
      padding: 0 23px 37px 23px; }
    .gallery-pictures .item__location {
      font-weight: 500;
      font-size: 9px;
      line-height: 11px;
      letter-spacing: 0.05em; }
      .gallery-pictures .item__location svg {
        width: 13px;
        height: auto; }
    .gallery-pictures .item__tag {
      font-size: 9px;
      line-height: 11px;
      letter-spacing: 0.05em; }
    .gallery-pictures .slick-dots {
      width: calc(100% - 46px);
      left: 25px;
      bottom: 12px; } }

@media (min-width: 991px) {
  .gallery-pictures {
    width: 47.10425%; }
    .gallery-pictures .item__caption {
      padding: 0 21px 47px 21px; }
    .gallery-pictures .item__location, .gallery-pictures .item__tag {
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.05em; }
    .gallery-pictures .item__location {
      margin-bottom: 4px; }
      .gallery-pictures .item__location svg {
        width: 12px; }
    .gallery-pictures .slick-dots {
      bottom: 19px;
      left: 23px;
      width: calc(100% - 42px); } }

.overview {
  -webkit-box-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap; }

.info-overview {
  background-color: #131418;
  color: #E6E6E6;
  padding: 18px 16px 19px 16px; }
  .info-overview .type {
    margin-bottom: 5px;
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.3em;
    text-transform: uppercase; }
  .info-overview .main-title {
    margin-bottom: 18px; }
  .info-overview .ranking {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-align: stretch;
            align-items: stretch; }
    .info-overview .ranking__position {
      padding-bottom: 6px;
      text-align: center;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
              align-items: center;
      -webkit-box-pack: center;
              justify-content: center;
      width: 56px;
      height: 56px;
      position: relative; }
    .info-overview .ranking__info {
      -webkit-box-flex: 1;
              flex: 1;
      padding-left: 19px;
      height: 56px; }
    .info-overview .ranking__number {
      font-weight: bold;
      font-size: 36px;
      line-height: 36px;
      text-align: center;
      color: #D1D1D1;
      font-family: "PlayfairDisplay";
      margin-bottom: 0;
      display: inline-block;
      position: relative; }
    .info-overview .ranking__hash {
      position: absolute;
      top: -13px;
      left: -7px;
      line-height: 1; }
    .info-overview .ranking__label {
      font-size: 9px;
      line-height: 11px;
      text-align: center;
      letter-spacing: 0.2em;
      color: #F2F2F2;
      margin-bottom: 0; }
    .info-overview .ranking__desc {
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.05em;
      margin-bottom: 7px; }
    .info-overview .ranking__network {
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      margin: 0;
      border-top: 0.5px solid rgba(255, 255, 255, 0.5); }
      .info-overview .ranking__network li {
        display: inline-block;
        vertical-align: middle;
        padding-left: 17px;
        padding-right: 17px;
        position: relative;
        height: 13px; }
        .info-overview .ranking__network li::before {
          content: '';
          width: 0.5px;
          height: 13px;
          position: absolute;
          top: 0;
          -webkit-transform: translateX(-50%);
                  transform: translateX(-50%);
          left: 0;
          background-color: rgba(255, 255, 255, 0.5); }
        .info-overview .ranking__network li:first-child {
          padding-left: 0; }
          .info-overview .ranking__network li:first-child::before {
            display: none; }
    .info-overview .ranking__link {
      color: #E6E6E6;
      display: -webkit-inline-box;
      display: inline-flex;
      -webkit-box-align: center;
              align-items: center; }
      .info-overview .ranking__link svg {
        margin-right: 6px; }
    .info-overview .ranking__bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
    .info-overview .ranking .b-top {
      height: 28px; }
    .info-overview .ranking .b-bottom {
      height: 28px;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: end;
              align-items: flex-end; }
  .info-overview .star {
    width: 86px;
    height: 13px;
    position: relative; }
    .info-overview .star__mask {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0; }
      .info-overview .star__mask--gray {
        z-index: 1; }
      .info-overview .star__mask--color {
        z-index: 2; }
    .info-overview .star__image {
      width: 86px;
      max-width: unset;
      position: absolute;
      top: 0;
      left: 0; }
  @media (min-width: 768px) {
    .info-overview {
      -webkit-box-flex: 1;
              flex: 1;
      padding: 31px 31px 20px 31px;
      background-color: transparent; }
      .info-overview .type {
        font-weight: 300;
        font-size: 9px;
        line-height: 11px;
        letter-spacing: 0.5em;
        color: #E6E6E6;
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
                align-items: center;
        margin-bottom: 11px; }
        .info-overview .type .star {
          margin-bottom: 0;
          margin-left: 18px;
          top: 1px; }
      .info-overview .main-title {
        margin-bottom: 15px; }
      .info-overview .ranking .rating-overview {
        padding: 0;
        background-color: transparent;
        color: #D1D1D1;
        top: 6px; }
        .info-overview .ranking .rating-overview::before {
          display: none; }
        .info-overview .ranking .rating-overview .item {
          display: -webkit-box;
          display: flex; }
          .info-overview .ranking .rating-overview .item__caption {
            display: -webkit-box;
            display: flex;
            flex-wrap: wrap;
            text-align: left;
            margin-left: 3px; }
          .info-overview .ranking .rating-overview .item__name {
            font-weight: 500;
            font-size: 7px;
            line-height: 9px;
            letter-spacing: 0.1em;
            color: #D1D1D1;
            opacity: 0.7;
            width: 100%;
            margin-bottom: 0;
            padding-left: 4px;
            position: relative;
            top: -3px; }
          .info-overview .ranking .rating-overview .item__count {
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: 0.05em;
            color: #D1D1D1;
            width: 100%;
            -webkit-box-ordinal-group: 0;
                    order: -1; }
          .info-overview .ranking .rating-overview .item .count--max {
            display: none; }
          .info-overview .ranking .rating-overview .item .count__hash {
            font-size: 7px;
            top: -2px;
            left: -2px; }
      .info-overview .ranking__position {
        width: 58px;
        height: 76px; }
      .info-overview .ranking__hash {
        top: -17px; }
      .info-overview .ranking__label {
        position: relative;
        top: -4px; }
      .info-overview .ranking__info {
        padding-left: 13px; }
      .info-overview .ranking__network {
        margin-top: 10px;
        font-size: 7px;
        line-height: 9px;
        letter-spacing: 0.2em; }
        .info-overview .ranking__network li {
          top: 0; } }
  @media (min-width: 991px) {
    .info-overview {
      padding-left: 5.98456%; }
      .info-overview .ranking__info {
        max-width: 410px;
        padding-left: 28px; }
      .info-overview .ranking__network {
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.2em;
        margin-top: 0; }
        .info-overview .ranking__network li {
          padding-left: 13px;
          padding-right: 13px;
          height: 20px; }
          .info-overview .ranking__network li::before {
            height: 17px; }
          .info-overview .ranking__network li:nth-child(1) svg {
            width: 17px;
            height: auto; }
          .info-overview .ranking__network li:nth-child(2) svg {
            width: 15px;
            height: auto; }
          .info-overview .ranking__network li:nth-child(3) svg {
            width: 15px;
            height: auto; }
      .info-overview .ranking__position {
        width: 83px;
        height: 109px; }
      .info-overview .ranking__number {
        font-size: 46px;
        line-height: 46px; }
      .info-overview .ranking__label {
        font-size: 8px;
        line-height: 10px;
        letter-spacing: 0.3em;
        top: 0;
        margin-top: 3px; }
      .info-overview .ranking__link svg {
        margin-right: 11px; }
      .info-overview .ranking__hash {
        top: -20px;
        left: -13px; }
        .info-overview .ranking__hash svg {
          width: 10px;
          height: auto; }
      .info-overview .ranking .rating-overview {
        top: -1px;
        flex-wrap: unset; }
        .info-overview .ranking .rating-overview .item {
          width: auto;
          margin-left: 22px;
          position: relative;
          padding-left: 45px; }
          .info-overview .ranking .rating-overview .item:first-child {
            margin-left: 0; }
          .info-overview .ranking .rating-overview .item .count__hash {
            font-size: 10px;
            top: -1px;
            left: -5px; }
          .info-overview .ranking .rating-overview .item__count {
            font-size: 23px;
            line-height: 28px;
            letter-spacing: 0.05em;
            margin-bottom: 1px; }
          .info-overview .ranking .rating-overview .item__name {
            font-size: 9px;
            line-height: 11px;
            letter-spacing: 0.1em;
            opacity: 0.7; }
          .info-overview .ranking .rating-overview .item__caption {
            margin-left: 0;
            display: block; }
          .info-overview .ranking .rating-overview .item__logo {
            position: absolute;
            top: 0;
            left: 0;
            width: 30px; }
          .info-overview .ranking .rating-overview .item__icon {
            width: 100%;
            height: auto; }
          .info-overview .ranking .rating-overview .item--1 .item__icon {
            width: 35px; }
          .info-overview .ranking .rating-overview .item--2 .item__icon {
            width: 39px; }
          .info-overview .ranking .rating-overview .item--3 .item__icon {
            width: 36px; }
      .info-overview .ranking .b-middle {
        height: 64px;
        padding-top: 5px; }
      .info-overview .ranking .b-bottom {
        height: 44px; }
      .info-overview .type {
        margin-bottom: 16px; }
      .info-overview .main-title {
        margin-bottom: 55px; } }

.rating-overview {
  background-color: #242D33;
  display: -webkit-box;
  display: flex;
  color: #D1D1D1;
  text-align: center;
  padding: 39px 0 32px 0;
  position: relative; }
  .rating-overview::before {
    content: '';
    width: 1px;
    height: 18px;
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50);
            transform: translateX(-50);
    background-color: #ffffff; }
  .rating-overview .item {
    width: 33.33333%; }
    .rating-overview .item a {
      color: #ffffff; }
    .rating-overview .item__name {
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      margin-bottom: 5px; }
    .rating-overview .item__icon {
      margin-bottom: 4px; }
    .rating-overview .item__count {
      font-weight: 600;
      font-size: 22px;
      line-height: 27px; }
    .rating-overview .item .count {
      position: relative;
      padding: 0 4px;
      display: inline-block; }
      .rating-overview .item .count:first-child::before {
        display: none; }
      .rating-overview .item .count::before {
        content: '';
        width: 1px;
        height: 19px;
        background-color: #8B8C8D;
        opacity: 0.9;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        left: -3px; }
      .rating-overview .item .count__hash {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        position: absolute;
        top: 0;
        left: -3px; }
      .rating-overview .item .count--max {
        position: relative;
        color: #979797;
        opacity: 0.9; }

.info-insta {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  -webkit-box-ordinal-group: 0;
          order: -1;
  background-color: #131418;
  margin: 0 auto;
  padding: 0 16px; }
  .info-insta__inner {
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    padding: 18px 0 15px 0px; }
  .info-insta__line {
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
  .info-insta__icon {
    width: 70px; }
  .info-insta__content {
    -webkit-box-flex: 1;
            flex: 1;
    margin-bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column; }
    .info-insta__content a {
      color: #ffffff; }
      .info-insta__content a:focus, .info-insta__content a:hover {
        color: #ffffff; }
    .info-insta__content .line-info {
      margin-top: 4px; }
      .info-insta__content .line-info:first-child {
        margin-top: 0; }
      .info-insta__content .line-info--account {
        -webkit-box-ordinal-group: 2;
                order: 1; }
      .info-insta__content .line-info--hashtag {
        -webkit-box-ordinal-group: 4;
                order: 3; }
      .info-insta__content .line-info--location {
        -webkit-box-ordinal-group: 3;
                order: 2; }
  .info-insta .icon-insta {
    width: 46px;
    height: auto; }
  @media (min-width: 768px) {
    .info-insta {
      -webkit-box-ordinal-group: 2;
              order: 1;
      padding: 0 31px; }
      .info-insta__line {
        bottom: auto;
        top: 0; }
      .info-insta__inner {
        padding: 22px 0 25px 0; }
      .info-insta__icon {
        width: 34px; }
      .info-insta__content {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
                flex-direction: row;
        flex-wrap: wrap; }
        .info-insta__content .line-info {
          margin: 2px 0 2px 15px; }
          .info-insta__content .line-info:first-child {
            margin: 2px 0 2px 15px; }
          .info-insta__content .line-info--account {
            -webkit-box-ordinal-group: 2;
                    order: 1; }
          .info-insta__content .line-info--hashtag {
            -webkit-box-ordinal-group: 3;
                    order: 2; }
          .info-insta__content .line-info--location {
            -webkit-box-ordinal-group: 4;
                    order: 3; }
      .info-insta .icon-insta {
        width: 34px; } }
  @media (min-width: 991px) {
    .info-insta {
      padding-left: 5.98456%;
      font-size: 12px;
      line-height: 15px; }
      .info-insta__line {
        max-width: 493px;
        width: 100%;
        height: auto;
        -webkit-transform: none;
                transform: none;
        left: 0; }
      .info-insta .icon-insta {
        width: 20px; } }

.featured {
  background-color: #ffffff;
  position: relative;
  padding-top: 53px;
  padding-bottom: 53px; }
  .featured::before {
    content: '';
    width: 1px;
    background-color: #242D33;
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    height: 24px; }
  .featured::after {
    content: '';
    width: 1px;
    background-color: #000000;
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    height: 17px;
    z-index: 1; }
  .featured .container {
    padding: 0;
    position: relative;
    z-index: 1; }
  .featured__title {
    font-weight: normal;
    margin-bottom: 28px;
    color: #000000; }
  .featured__logos {
    margin-bottom: 41px;
    position: relative;
    z-index: 1; }
    .featured__logos .item {
      color: #B3B3B3;
      height: 25px;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
              align-items: center;
      padding-left: 20px; }
      .featured__logos .item__image {
        height: 25px;
        -o-object-fit: contain;
           object-fit: contain; }
      .featured__logos .item:focus {
        outline: none; }
      .featured__logos .item:first-child {
        padding-left: 18px; }
  .featured .reviews {
    font-family: "PlayfairDisplay";
    font-size: 14px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.9);
    text-align: center;
    position: relative;
    z-index: 1; }
    .featured .reviews::before {
      content: '';
      width: 54px;
      height: 1px;
      background-color: #000000;
      position: absolute;
      top: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%); }
    .featured .reviews .item {
      position: relative;
      padding: 42px 16px 87px 16px;
      overflow: hidden; }
      .featured .reviews .item:focus {
        outline: none; }
      .featured .reviews .item__bg {
        z-index: 1;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%; }
      .featured .reviews .item__icon {
        position: absolute;
        top: 22px;
        left: 16px;
        color: #F7F7F7;
        z-index: 3; }
      .featured .reviews .item__text {
        position: relative;
        z-index: 5;
        margin-bottom: 31px;
        color: #000000; }
      .featured .reviews .item__image {
        margin: 0 auto; }
    .featured .reviews ul,
    .featured .reviews .item__text__content {
      margin-bottom: 0;
      padding-left: 23px;
      padding-right: 12px;
      margin-top: 8px; }
      .featured .reviews ul:first-child,
      .featured .reviews .item__text__content:first-child {
        margin-top: 0; }
      .featured .reviews ul pre,
      .featured .reviews .item__text__content pre {
        font-family: "PlayfairDisplay";
        font-size: 20px;
        line-height: 30px;
        overflow: unset; }
    .featured .reviews .slick-dots {
      margin: 0 -4px;
      position: absolute;
      bottom: 41px;
      left: 16px;
      width: calc(100% - 32px);
      z-index: 7;
      display: -webkit-box;
      display: flex;
      -webkit-box-pack: center;
              justify-content: center; }
      .featured .reviews .slick-dots li {
        padding: 0 4px;
        height: 20px;
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
                align-items: center;
        width: 43px;
        -webkit-transition: width .3s ease;
        transition: width .3s ease;
        display: inline-block; }
        .featured .reviews .slick-dots li.slick-active {
          width: 85px; }
          .featured .reviews .slick-dots li.slick-active button {
            opacity: 1; }
        .featured .reviews .slick-dots li button {
          width: 100%;
          max-width: 100%;
          height: 1.5px;
          overflow: hidden;
          border: none;
          background: none;
          background-color: #242D33;
          opacity: 0.3;
          font-size: 0;
          -webkit-transition: opacity .3s ease;
          transition: opacity .3s ease; }
    .featured .reviews.hide-dots .slick-dots {
      display: none; }
  .featured__bg {
    position: absolute;
    bottom: 0;
    right: 0; }
  @media (min-width: 768px) {
    .featured {
      padding-top: 65px;
      padding-bottom: 0; }
      .featured::before {
        display: none; }
      .featured::after {
        height: 21px; }
      .featured .reviews {
        font-size: 15px;
        line-height: 24px;
        padding-bottom: 61px; }
        .featured .reviews::before {
          display: none; }
        .featured .reviews .item {
          padding: 0 0 0 60px; }
          .featured .reviews .item__icon {
            top: 0px;
            left: 24px; }
          .featured .reviews .item__text {
            text-align: center;
            margin-bottom: 41px; }
          .featured .reviews .item__author {
            font-size: 11px;
            line-height: 16px;
            text-align: center; }
            .featured .reviews .item__author li {
              display: inline-block;
              padding-left: 5px;
              position: relative;
              margin-right: 10px; }
              .featured .reviews .item__author li:last-child {
                margin-right: 0; }
              .featured .reviews .item__author li::before {
                content: '';
                width: 2px;
                height: 2px;
                position: absolute;
                top: 8px;
                left: -2px;
                background-color: #1A1A1A;
                border-radius: 50%; }
          .featured .reviews .item__text__content,
          .featured .reviews .item ul {
            padding-left: 0; }
        .featured .reviews .slick-dots {
          width: auto;
          left: 0; }
      .featured__logos {
        width: 100%;
        overflow: hidden;
        margin-bottom: 20px; }
        .featured__logos .item {
          display: block;
          padding: 0;
          margin: 0;
          text-align: center; }
          .featured__logos .item:first-child {
            padding: 0; }
          .featured__logos .item__image {
            display: inline-block; }
      .featured .slick-dots {
        left: 38px;
        bottom: 21px; }
        .featured .slick-dots li {
          width: 13px; }
        .featured .slick-dots li.slick-active {
          width: 40px; } }
  @media (min-width: 991px) {
    .featured {
      padding-top: 74px;
      padding-bottom: 57px; }
      .featured:after {
        height: 40px; }
      .featured__title {
        margin-bottom: 52px; }
      .featured__logos {
        margin-bottom: 69px; }
        .featured__logos .item {
          height: 30px; }
          .featured__logos .item__image {
            height: 30px; }
      .featured .reviews {
        font-size: 20px;
        line-height: 32px;
        position: relative;
        left: -72px;
        padding-bottom: 76px; }
        .featured .reviews::before {
          display: none; }
        .featured .reviews .item {
          padding: 0 0 0 93px; }
          .featured .reviews .item__author {
            font-size: 18px;
            line-height: 27px;
            margin-top: 15px; }
            .featured .reviews .item__author li {
              padding-left: 11px; }
              .featured .reviews .item__author li::before {
                width: 4px;
                height: 4px;
                top: 13px; }
          .featured .reviews .item__icon {
            width: 51px;
            height: auto;
            top: 2px;
            left: 21px; }
        .featured .reviews .slick-dots {
          bottom: 22px;
          left: 90px; }
        .featured .reviews ul,
        .featured .reviews .item__text__content {
          padding-left: 0; }
      .featured .slick-dots {
        left: 72px; } }

.location {
  background: -webkit-gradient(linear, left top, left bottom, from(#F8F8F8), to(rgba(248, 248, 248, 0)));
  background: linear-gradient(180deg, #F8F8F8 0%, rgba(248, 248, 248, 0) 100%);
  padding-top: 51px;
  position: relative; }
  .location::after {
    content: '';
    width: 1px;
    background-color: #000000;
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    height: 17px; }
  .location__title {
    color: #212121;
    padding-bottom: 20px; }
  .location__clock {
    position: absolute;
    bottom: 0;
    left: 0; }
  .location .container {
    position: relative;
    z-index: 1; }
  .location .map {
    height: 450px;
    background-color: #C4C4C4; }
  .location .address {
    background-color: #242D33;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    color: #E6E6E6;
    padding-top: 24px;
    padding-bottom: 17px;
    padding-left: 15px;
    padding-right: 15px; }
    .location .address__title {
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #E6E6E6; }
    .location .address__desc {
      font-size: 13px;
      line-height: 15px;
      text-align: center;
      letter-spacing: 0.05em;
      margin-bottom: 16px; }
      .location .address__desc p {
        margin-bottom: 0;
        margin-top: 3px; }
        .location .address__desc p:first-child {
          margin-top: 0; }
  .location .distances {
    padding: 0 23px;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
            justify-content: space-between;
    position: relative; }
    .location .distances::before {
      content: '';
      width: 1px;
      height: 63px;
      position: absolute;
      top: 50%;
      -webkit-transform: translateX(-50%) translateY(-50%);
              transform: translateX(-50%) translateY(-50%);
      left: 50%;
      background-color: #727F87;
      opacity: .2; }
    .location .distances .item {
      text-align: center;
      width: 50%; }
      .location .distances .item__km {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.05em;
        font-family: "PlayfairDisplay";
        -webkit-font-feature-settings: "pnum" on, "lnum" on;
                font-feature-settings: "pnum" on, "lnum" on;
        margin-bottom: 5px; }
      .location .distances .item__place {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.05em; }
  @media (min-width: 768px) {
    .location {
      padding-top: 68px; }
      .location:after {
        height: 21px; }
      .location__inner {
        padding-left: 6.92857em;
        position: relative;
        max-width: 645px;
        margin: 0 auto; }
      .location .map {
        height: 295px; }
      .location .address {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        left: 0;
        padding: 18px 20px;
        width: 187px; }
        .location .address__title {
          font-size: 11px;
          line-height: 13px;
          letter-spacing: 0.2em;
          text-align: left;
          margin-bottom: 5px; }
        .location .address__desc {
          font-size: 9px;
          line-height: 11px;
          letter-spacing: 0.1em;
          text-align: left;
          margin-bottom: 11px; }
      .location .distances {
        flex-wrap: wrap;
        padding: 0;
        border-top: 0.5px solid rgba(114, 127, 135, 0.7); }
        .location .distances::before {
          display: none; }
        .location .distances .item {
          width: 100%;
          text-align: left;
          margin-top: 4px; }
          .location .distances .item__km {
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.05em;
            margin-bottom: 0; }
          .location .distances .item__place {
            font-size: 9px;
            line-height: 13px;
            letter-spacing: 0.1em;
            margin-bottom: 0; }
            .location .distances .item__place br {
              display: none; }
      .location--without-line::after {
        display: none;
        margin-top: -54px; } }
  @media (min-width: 991px) {
    .location {
      padding-top: 84px; }
      .location:after {
        height: 40px; }
      .location__title {
        padding-bottom: 43px; }
      .location__inner {
        max-width: 1036px;
        padding-left: 211px;
        margin: 0 auto; }
      .location .map {
        height: 431px; }
      .location .address {
        width: 303px;
        padding: 45px 36px; }
        .location .address__title {
          font-size: 12px;
          line-height: 15px;
          margin-bottom: 10px; }
        .location .address__desc {
          font-size: 11px;
          line-height: 13px;
          letter-spacing: 0.1em;
          margin-bottom: 30px; }
      .location .distances .item {
        margin-top: 15px; }
        .location .distances .item:first-child {
          margin-top: 23px; }
        .location .distances .item__km {
          font-size: 29px;
          line-height: 39px; }
        .location .distances .item__place {
          font-size: 10px;
          line-height: 14px; }
      .location--without-line {
        margin-top: -49px; } }

.booking {
  position: relative;
  background-color: #ffffff;
  padding: 54px 0 46px 0; }
  .booking:after {
    content: '';
    width: 1px;
    height: 29px;
    background-color: #131418;
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
  .booking__bg {
    position: absolute;
    bottom: 0;
    right: 0; }
  .booking .container {
    position: relative;
    z-index: 1; }
  .booking__title {
    color: #212121;
    margin-bottom: 27px; }
  .booking__brand {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
            justify-content: space-between;
    -webkit-box-align: end;
            align-items: flex-end;
    margin-bottom: 0;
    max-width: 288px;
    margin: 0 auto; }
    .booking__brand .item {
      width: calc(50% - 6px);
      margin: 0; }
      .booking__brand .item__link {
        display: -webkit-inline-box;
        display: inline-flex;
        -webkit-box-align: center;
                align-items: center;
        -webkit-box-pack: center;
                justify-content: center;
        text-align: center;
        letter-spacing: 0.1em;
        color: #ffffff;
        font-weight: 500;
        height: 43px;
        font-size: 9px;
        line-height: 13px;
        width: 100%;
        position: relative; }
        .booking__brand .item__link:hover {
          background-color: #ffffff; }
      .booking__brand .item__bg {
        position: absolute;
        top: 0;
        left: 0; }
      .booking__brand .item__icon {
        max-width: 22px;
        height: auto;
        margin-right: 15px;
        position: relative;
        z-index: 1; }
      .booking__brand .item__text {
        position: relative;
        z-index: 1; }
  @media (min-width: 768px) {
    .booking {
      padding: 66px 0 79px 0; }
      .booking::after {
        top: auto;
        bottom: 0;
        height: 43px; }
      .booking__bg {
        width: auto;
        bottom: -90px; }
      .booking__title {
        margin-bottom: 22px; }
        .booking__title br {
          display: none; } }
  @media (min-width: 991px) {
    .booking {
      padding: 83px 0 155px 0; }
      .booking::after {
        height: 80px; }
      .booking__bg {
        bottom: -128px; }
      .booking__title {
        margin-bottom: 52px; }
        .booking__title br {
          display: none; }
      .booking__brand {
        max-width: 440px; }
        .booking__brand .item {
          width: calc(50% - 10px); }
          .booking__brand .item__link {
            height: 59px;
            font-weight: 500;
            font-size: 12px;
            line-height: 17px; }
            .booking__brand .item__link:hover {
              background-color: #ffffff; }
          .booking__brand .item__bg {
            width: 100%;
            height: 59px; }
          .booking__brand .item__icon {
            max-width: 38px;
            height: auto;
            margin-right: 20px; } }

.featured-hotels {
  background-color: #242D33;
  padding-bottom: 75px;
  padding-top: 50px; }
  .featured-hotels__title {
    color: #ffffff;
    margin-bottom: 30px; }
  .featured-hotels .item {
    position: relative;
    outline: none; }
    .featured-hotels .item:hover {
      outline: none; }
    .featured-hotels .item__image {
      width: 100%;
      position: relative;
      overflow: hidden; }
      .featured-hotels .item__image .img-mask {
        width: 100%;
        opacity: 1;
        visibility: hidden; }
      .featured-hotels .item__image .img {
        -o-object-fit: cover;
           object-fit: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0; }
    .featured-hotels .item__caption {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: end;
              align-items: flex-end;
      color: #ffffff;
      padding: 0 16px 17px 16px; }
      .featured-hotels .item__caption:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000000;
        opacity: .7; }
      .featured-hotels .item__caption__inner {
        position: relative;
        z-index: 5; }
    .featured-hotels .item__view {
      text-transform: uppercase;
      border: 1px solid #ffffff;
      width: 144px;
      height: 43px;
      font-size: 11px;
      line-height: 13px;
      text-align: center;
      letter-spacing: 0.4em;
      display: -webkit-inline-box;
      display: inline-flex; }
    .featured-hotels .item__type {
      font-size: 8px;
      line-height: 10px;
      margin-bottom: 3px;
      letter-spacing: 0.5em;
      text-transform: uppercase;
      z-index: 1; }
    .featured-hotels .item__link {
      color: #ffffff; }
    .featured-hotels .item__title {
      font-style: italic;
      font-weight: bold;
      font-size: 16px;
      line-height: 21px;
      color: #ffffff;
      font-family: "PlayfairDisplay";
      margin-bottom: 3px; }
    .featured-hotels .item__address {
      margin-bottom: 0;
      font-weight: normal;
      font-size: 13px;
      line-height: 15px; }
    .featured-hotels .item__desc {
      margin-bottom: 0;
      font-size: 13px;
      line-height: 15px;
      letter-spacing: 0.05em; }
    .featured-hotels .item__ranking {
      background-color: #131418;
      font-weight: bold;
      font-size: 20px;
      line-height: 27px;
      text-align: center;
      color: #D1D1D1;
      font-family: "PlayfairDisplay";
      width: 36px;
      height: 38px;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
              align-items: center;
      -webkit-box-pack: center;
              justify-content: center; }
    .featured-hotels .item__number {
      display: inline-block;
      position: relative; }
    .featured-hotels .item__hash {
      display: inline-block;
      position: absolute;
      top: -8px;
      left: -5px; }
    .featured-hotels .item--has-spacing {
      margin-top: 15px; }
  .featured-hotels .slick-dots {
    margin: 0 -4px;
    position: absolute;
    bottom: -30px;
    left: 16px;
    width: calc(100% - 32px);
    z-index: 7;
    text-align: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center; }
    .featured-hotels .slick-dots li {
      padding: 0 4px;
      height: 20px;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
              align-items: center;
      width: 43px;
      -webkit-transition: width .3s ease;
      transition: width .3s ease;
      display: inline-block; }
      .featured-hotels .slick-dots li.slick-active {
        width: 85px; }
        .featured-hotels .slick-dots li.slick-active button {
          opacity: 1; }
      .featured-hotels .slick-dots li button {
        width: 100%;
        max-width: 100%;
        height: 1.5px;
        overflow: hidden;
        border: none;
        background: none;
        background-color: #ffffff;
        opacity: 0.3;
        font-size: 0;
        -webkit-transition: opacity .3s ease;
        transition: opacity .3s ease; }
  @media (max-width: 768px) {
    .featured-hotels--special-mobile {
      padding-bottom: 7px; }
      .featured-hotels--special-mobile .container {
        padding: 0; }
      .featured-hotels--special-mobile .item {
        border-top: 3px solid #ffffff;
        margin-top: 0; }
        .featured-hotels--special-mobile .item:first-child {
          border-top: none; }
        .featured-hotels--special-mobile .item__caption {
          position: relative;
          top: auto;
          left: auto;
          width: 100%;
          background-color: #000000;
          padding: 13px 16px;
          -webkit-box-flex: 1;
                  flex: 1; }
          .featured-hotels--special-mobile .item__caption::before {
            display: none; }
        .featured-hotels--special-mobile .item__type {
          font-size: 8px;
          line-height: 10px;
          letter-spacing: 0.3em;
          margin-bottom: 8px;
          text-transform: uppercase; }
        .featured-hotels--special-mobile .item__title {
          font-style: italic;
          font-weight: bold;
          margin-bottom: 5px;
          font-size: 16px;
          line-height: 21px;
          letter-spacing: 0.02em; }
        .featured-hotels--special-mobile .item__location {
          font-size: 11px;
          line-height: 13px;
          margin-bottom: 0; } }
  @media (min-width: 768px) {
    .featured-hotels {
      background-color: transparent;
      padding-top: 28px;
      padding-bottom: 0; }
      .featured-hotels .container {
        padding: 0;
        max-width: unset; }
      .featured-hotels__title {
        color: #000000;
        margin-bottom: 32px; }
      .featured-hotels__slider {
        display: -webkit-box;
        display: flex;
        flex-wrap: wrap; }
      .featured-hotels .item {
        width: 20%; }
        .featured-hotels .item__ranking {
          font-size: 20px;
          line-height: 27px;
          width: 35px;
          height: 35px;
          top: 3px;
          right: 3px;
          padding: 0; }
        .featured-hotels .item__hash {
          top: -8px;
          left: -5px; }
        .featured-hotels .item__caption {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
                  flex-direction: column;
          -webkit-box-pack: end;
                  justify-content: flex-end;
          -webkit-box-align: start;
                  align-items: flex-start;
          opacity: 1;
          -webkit-transition: opacity 0.3s ease;
          transition: opacity 0.3s ease;
          padding: 0 11px 5px 11px; }
        .featured-hotels .item__type {
          font-size: 6px;
          line-height: 7px; }
        .featured-hotels .item__title {
          font-size: 11px;
          line-height: 13px;
          margin-bottom: 3px; }
        .featured-hotels .item__address {
          font-size: 7px;
          line-height: 9px;
          letter-spacing: 0.05em; }
        .featured-hotels .item__desc {
          display: none; }
        .featured-hotels .item:hover .item__caption {
          opacity: 1; }
        .featured-hotels .item--has-spacing {
          margin-top: 0; } }
  @media (min-width: 991px) {
    .featured-hotels {
      padding-top: 35px; }
      .featured-hotels__title {
        margin-bottom: 43px; }
      .featured-hotels .item__caption {
        padding: 0 17px 17px 17px;
        opacity: 1; }
      .featured-hotels .item__type {
        font-size: 8px;
        line-height: 10px;
        letter-spacing: 0.5em;
        margin-bottom: 7px; }
      .featured-hotels .item__title {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 16px; }
      .featured-hotels .item__address {
        font-size: 12px;
        line-height: 15px; }
      .featured-hotels .item__ranking {
        width: 54px;
        height: 59px; }
      .featured-hotels .item__number {
        font-size: 30px;
        line-height: 30px;
        top: 0;
        right: 0; }
      .featured-hotels .item__hash {
        top: -13px;
        left: -10px; }
        .featured-hotels .item__hash svg {
          width: 9px;
          height: auto; }
      .featured-hotels--has-hover .item__caption {
        opacity: 0; }
      .featured-hotels--has-hover .item:hover .item__caption {
        opacity: 1; } }

.filter {
  padding: 17px 0;
  position: relative;
  z-index: 2; }
  .filter__bar {
    padding: 0 15px 0 0; }
  .filter__back {
    width: 52px;
    height: 42px;
    text-align: center;
    color: #000000;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: center;
            justify-content: center; }
  .filter__icon {
    width: 54px;
    border: none;
    background: none;
    outline: none; }
  .filter .input-group {
    height: 42px;
    background: #ffffff;
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1);
    border-radius: 23px; }
    .filter .input-group .input-group-prepend {
      width: 40px;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
              align-items: center;
      padding-left: 18px; }
    .filter .input-group .form-control {
      padding: 0;
      border: none;
      background: none;
      height: 100%;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
              align-items: center;
      color: #000000; }
      .filter .input-group .form-control .text {
        display: inline-block;
        padding: 0 8px;
        position: relative; }
        .filter .input-group .form-control .text:first-child {
          padding-left: 0; }
          .filter .input-group .form-control .text:first-child::before {
            display: none; }
        .filter .input-group .form-control .text::before {
          content: '';
          display: block;
          width: 3px;
          height: 3px;
          background-color: #000000;
          position: absolute;
          top: 9px;
          left: -1px;
          border-radius: 50%; }
  .filter .checkboxs {
    padding-top: 12px; }
    .filter .checkboxs .form-check {
      margin-top: 8px;
      margin-right: 5px; }
    .filter .checkboxs .form-check-input {
      display: none; }
      .filter .checkboxs .form-check-input:checked + label {
        background-color: #242D33;
        color: #ffffff; }
    .filter .checkboxs .form-check-label {
      font-weight: 500;
      height: 26px;
      border-radius: 13px;
      display: -webkit-inline-box;
      display: inline-flex;
      -webkit-box-align: center;
              align-items: center;
      font-size: 10px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.05em;
      color: #626264;
      background-color: #F8F8F8;
      padding: 0 13px; }
  .filter .dropdown-filter {
    margin: 0 -4px;
    display: -webkit-box;
    display: flex; }
    .filter .dropdown-filter .dropdown {
      width: 25%;
      padding: 0 4px; }
      .filter .dropdown-filter .dropdown.is-all .dropdown-btn .all {
        display: inline-block; }
      .filter .dropdown-filter .dropdown.is-all .dropdown-btn .star {
        display: none; }
    .filter .dropdown-filter .dropdown-btn {
      background: none;
      border: none;
      width: 100%;
      box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1);
      border-radius: 28px;
      height: 32px;
      border: none;
      background-color: #ffffff;
      padding: 0 27px 0 13px;
      font-weight: 500;
      font-size: 11px;
      line-height: 20px;
      letter-spacing: 0.1em;
      color: #000000;
      text-transform: uppercase;
      position: relative;
      text-align: left;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
              align-items: center; }
      .filter .dropdown-filter .dropdown-btn .all {
        display: none; }
      .filter .dropdown-filter .dropdown-btn .star {
        display: inline-block; }
      .filter .dropdown-filter .dropdown-btn:hover {
        box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1) !important; }
    .filter .dropdown-filter .dropdown-icon {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      right: 13px; }
    .filter .dropdown-filter .dropdown-menu {
      width: calc(100% - 12px);
      min-width: 230px;
      border: none;
      box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1);
      margin-top: 10px;
      min-width: unset; }
      .filter .dropdown-filter .dropdown-menu::before, .filter .dropdown-filter .dropdown-menu::after {
        bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none; }
      .filter .dropdown-filter .dropdown-menu:after {
        border-color: rgba(136, 183, 213, 0);
        border-bottom-color: #ffffff;
        border-width: 5px;
        margin-left: -5px; }
      .filter .dropdown-filter .dropdown-menu::before {
        border-color: rgba(194, 225, 245, 0);
        border-bottom-color: #ffffff;
        border-width: 6px;
        margin-left: -6px; }
    .filter .dropdown-filter .dropdown-inner {
      max-height: 175px;
      overflow-y: auto; }
    .filter .dropdown-filter .dropdown-item {
      padding: 7px 13px;
      font-size: 12px; }
      .filter .dropdown-filter .dropdown-item--checkbox {
        padding-left: 30px; }
        .filter .dropdown-filter .dropdown-item--checkbox .form-check-input {
          margin-top: 2px; }
    .filter .dropdown-filter .star {
      width: 86px;
      height: 13px;
      position: relative;
      display: inline-block;
      top: 2px;
      margin-left: 5px; }
      .filter .dropdown-filter .star__mask {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0; }
        .filter .dropdown-filter .star__mask--gray {
          z-index: 1; }
        .filter .dropdown-filter .star__mask--color {
          z-index: 2; }
      .filter .dropdown-filter .star__image {
        width: 86px;
        max-width: unset;
        position: absolute;
        top: 0;
        left: 0; }
  @media (min-width: 768px) {
    .filter {
      padding-top: 119px; } }
  @media (min-width: 991px) {
    .filter {
      padding-top: 30px;
      padding-bottom: 31px; }
      .filter .dropdown-filter {
        margin: 0 -6px; }
        .filter .dropdown-filter .dropdown {
          max-width: 242px;
          padding: 0 6px; }
        .filter .dropdown-filter .dropdown-btn {
          font-weight: 500;
          font-size: 11px;
          line-height: 20px;
          letter-spacing: 0.1em;
          height: 44px;
          box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1);
          padding: 0 35px 0 18px; }
        .filter .dropdown-filter .dropdown-icon {
          width: 14px;
          height: auto;
          right: 17px; } }

.result-head {
  position: relative;
  padding-top: 75px; }
  .result-head__bg {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    display: none; }
  @media (min-width: 768px) {
    .result-head__bg {
      width: auto;
      display: block; } }
  @media (min-width: 991px) {
    .result-head {
      padding-top: 81px; } }

.hotel-intro {
  color: #000000;
  padding: 11px 0 20px 0;
  position: relative;
  z-index: 1; }
  .hotel-intro__type {
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    margin-bottom: 5px; }
  .hotel-intro__name {
    font-style: italic;
    font-weight: normal;
    font-size: 36px;
    line-height: 48px;
    letter-spacing: 0.05em;
    font-family: "PlayfairDisplay";
    margin-bottom: 12px; }
  .hotel-intro__desc, .hotel-intro__author {
    font-size: 14px;
    line-height: 150%;
    opacity: 0.9;
    margin-bottom: 0;
    font-family: "PlayfairDisplay"; }
  .hotel-intro__desc {
    margin-bottom: 8px; }
  .hotel-intro__author {
    font-style: italic;
    text-align: right; }
  @media (min-width: 768px) {
    .hotel-intro {
      padding: 17px 0 59px 64px; }
      .hotel-intro__type {
        font-size: 7px;
        line-height: 9px;
        letter-spacing: 0.5em;
        margin-bottom: 6px; }
      .hotel-intro__name {
        font-size: 28px;
        line-height: 37px;
        letter-spacing: 0.05em;
        margin-bottom: 4px; }
      .hotel-intro__desc, .hotel-intro__author {
        max-width: 443px; }
      .hotel-intro__desc {
        font-size: 15px;
        line-height: 24px;
        position: relative;
        margin-bottom: 3px; }
      .hotel-intro__author {
        font-size: 15px;
        line-height: 22px; }
      .hotel-intro__quote {
        position: absolute;
        top: -6px;
        left: -40px; } }
  @media (min-width: 991px) {
    .hotel-intro__inner {
      padding-left: 19px; }
    .hotel-intro__type {
      font-weight: 300;
      font-size: 9px;
      line-height: 11px;
      letter-spacing: 0.5em;
      margin-bottom: 8px; }
    .hotel-intro__name {
      font-size: 48px;
      line-height: 64px;
      letter-spacing: 0.05em;
      margin-bottom: 16px; }
    .hotel-intro__desc {
      font-size: 17px;
      line-height: 160%; }
    .hotel-intro__desc, .hotel-intro__author {
      max-width: 590px; }
    .hotel-intro__quote {
      width: 48px;
      height: auto;
      top: 6px;
      left: -67px; }
    .hotel-intro__author {
      font-size: 15px;
      line-height: 150%;
      color: #000000;
      opacity: 0.9; } }

.modal--more-filter {
  padding-right: 0 !important; }
  .modal--more-filter .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    max-width: unset; }
  .modal--more-filter .modal-content {
    width: 100%;
    height: 100%;
    padding-bottom: 61px;
    padding-top: 73px;
    border: none;
    border-radius: 0;
    position: relative;
    background-color: rgba(0, 0, 0, 0.9);
    position: fixed;
    top: 0;
    right: 0;
    -webkit-transition: right .3s ease;
    transition: right .3s ease; }
    .modal--more-filter .modal-content.move-left {
      right: 100%; }
  .modal--more-filter .modal-footer {
    height: 61px;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
            align-items: center;
    box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.08);
    border: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #ffffff; }
  .modal--more-filter .modal-header {
    border: none;
    padding: 0 16px;
    position: absolute;
    padding-top: 43px;
    top: 0;
    left: 0;
    width: 100%; }
    .modal--more-filter .modal-header .close {
      width: 38px;
      height: 45px;
      background-color: transparent;
      position: absolute;
      top: 0;
      right: 0;
      padding: 0;
      margin: 0;
      color: #ffffff;
      opacity: 1; }
  .modal--more-filter .modal-body {
    height: 100%;
    overflow-y: auto; }
  .modal--more-filter .small-title {
    font-size: 20px;
    line-height: 150%;
    color: #ffffff;
    opacity: 0.9;
    font-weight: normal;
    font-family: "PlayfairDisplay"; }
  .modal--more-filter .clear {
    font-weight: 500;
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    border: none;
    background: none;
    border: none;
    color: #000000; }
  .modal--more-filter .apply {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.3em;
    border: 1px solid #242D33;
    height: 40px;
    width: 130px;
    background: none;
    margin: 0; }
  .modal--more-filter .filter-border {
    border-top: 1px solid rgba(255, 255, 255, 0.2); }
    .modal--more-filter .filter-border:first-child {
      border: none; }
  .modal--more-filter .filter-address {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #ffffff;
    padding-top: 15px;
    padding-bottom: 13px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: justify;
            justify-content: space-between;
    padding-right: 9px; }
    .modal--more-filter .filter-address__icon {
      position: relative;
      top: 1px; }
    .modal--more-filter .filter-address:first-child {
      padding-top: 8px; }
  .modal--more-filter .filter-group {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #000000;
    padding-top: 18px;
    padding-bottom: 16px; }
    .modal--more-filter .filter-group__title {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #ffffff; }
  .modal--more-filter .form-check {
    padding: 0;
    margin-top: 10px; }
    .modal--more-filter .form-check:first-child {
      margin-top: 0; }
    .modal--more-filter .form-check-input {
      display: none; }
      .modal--more-filter .form-check-input:checked + label .form-check-icon {
        opacity: 1; }
    .modal--more-filter .form-check-label {
      padding-left: 26px;
      position: relative;
      height: 20px;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
              align-items: center;
      color: #ffffff; }
      .modal--more-filter .form-check-label::before {
        content: '';
        position: absolute;
        top: 1px;
        left: 0;
        width: 18px;
        height: 18px;
        border: 1px solid #E1E1E1;
        box-sizing: border-box;
        border-radius: 4px; }
    .modal--more-filter .form-check-icon {
      position: absolute;
      top: 6px;
      left: 5px;
      opacity: 0;
      -webkit-transition: opacity .3s ease;
      transition: opacity .3s ease; }
  .modal--more-filter .filter-location {
    overflow-y: auto;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    right: -100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.9);
    padding-top: 100px;
    -webkit-transition: right .3s ease;
    transition: right .3s ease; }
    .modal--more-filter .filter-location.move-left {
      right: 0; }
    .modal--more-filter .filter-location.opened {
      right: 0; }
    .modal--more-filter .filter-location__title {
      font-size: 20px;
      line-height: 150%;
      color: #ffffff;
      opacity: 0.9;
      font-family: "PlayfairDisplay";
      position: absolute;
      top: 48px;
      left: 16px; }
    .modal--more-filter .filter-location__icon {
      margin-right: 7px; }
    .modal--more-filter .filter-location__list {
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #ffffff;
      margin-bottom: 0;
      padding: 0 16px;
      height: 100%;
      overflow-y: auto; }
      .modal--more-filter .filter-location__list .item {
        padding-top: 12px;
        padding-bottom: 10px;
        border-top: 1px solid rgba(0, 0, 0, 0.16); }
  .modal--more-filter .star {
    width: 86px;
    height: 13px;
    position: relative; }
    .modal--more-filter .star__mask {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0; }
      .modal--more-filter .star__mask--gray {
        z-index: 1; }
      .modal--more-filter .star__mask--color {
        z-index: 2; }
    .modal--more-filter .star__image {
      width: 86px;
      max-width: unset;
      position: absolute;
      top: 0;
      left: 0; }

.about-us {
  position: relative;
  padding-top: 55px;
  padding-bottom: 57px; }
  .about-us .container {
    position: relative;
    z-index: 1; }
  .about-us .main-title {
    margin-bottom: 18px; }
  .about-us__desc {
    margin-bottom: 38px; }
  .about-us__bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; }
  .about-us__desc {
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #000000; }
  .about-us__images {
    margin: 0 auto;
    margin-bottom: 36px;
    height: 257px;
    position: relative;
    max-width: 288px; }
    .about-us__images .item {
      position: absolute;
      -o-object-fit: cover;
         object-fit: cover; }
      .about-us__images .item--1 {
        width: 32.63889%;
        height: 94px;
        top: 0;
        left: 0; }
      .about-us__images .item--2 {
        width: 66.31944%;
        height: 94px;
        top: 0;
        right: 0; }
      .about-us__images .item--3 {
        width: 23.95833%;
        height: 75px;
        top: 97px;
        left: 0; }
      .about-us__images .item--4 {
        width: 23.95833%;
        height: 75px;
        top: 97px;
        left: 25%; }
      .about-us__images .item--5 {
        width: 48.95833%;
        bottom: 0;
        left: 0;
        height: 82px; }
      .about-us__images .item--6 {
        width: 50%;
        height: 160px;
        bottom: 0;
        right: 0; }
  .about-us__intro {
    font-family: "PlayfairDisplay";
    font-size: 15px;
    line-height: 150%;
    text-align: center;
    color: #000000;
    opacity: 0.9;
    text-align: center;
    margin-bottom: 0;
    position: relative;
    padding: 0 11px;
    margin-top: 0;
    padding-top: 27px;
    display: inline-block; }
    .about-us__intro::before {
      content: '';
      width: 33px;
      height: 1px;
      background-color: #000000;
      position: absolute;
      top: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%); }
    .about-us__intro .quote {
      position: absolute;
      top: 7px;
      left: 3px;
      color: #F7F7F7; }
  @media (min-width: 768px) {
    .about-us {
      padding-top: 69px;
      padding-bottom: 70px;
      overflow: hidden; }
      .about-us__desc {
        max-width: 406px;
        margin: 0 auto;
        margin-bottom: 34px; }
      .about-us .main-title {
        margin-bottom: 11px; }
      .about-us__images {
        width: 100%;
        max-width: 648px;
        height: 242px;
        margin: 0 auto;
        position: relative; }
        .about-us__images .item {
          position: absolute;
          margin: 0;
          -o-object-fit: cover;
             object-fit: cover; }
          .about-us__images .item--1 {
            width: 32.40741%;
            height: 143px;
            bottom: 0;
            right: 0;
            top: auto;
            left: auto; }
          .about-us__images .item--2 {
            width: 32.40741%;
            height: 95px;
            top: 0;
            right: 0;
            bottom: auto;
            left: auto; }
          .about-us__images .item--3 {
            width: 16.2037%;
            height: 123px;
            top: 0;
            bottom: auto;
            left: 34.25926%;
            right: auto; }
          .about-us__images .item--4 {
            width: 16.04938%;
            height: 123px;
            top: 0;
            bottom: auto;
            right: 33.02469%;
            left: auto; }
          .about-us__images .item--5 {
            width: 32.71605%;
            height: 115px;
            bottom: 0;
            top: auto;
            right: auto;
            left: 34.25926%; }
          .about-us__images .item--6 {
            width: 33.7963%;
            height: 242px;
            top: 0;
            left: 0; }
      .about-us__intro {
        max-width: 386px;
        margin-top: 0;
        padding-top: 30px; }
        .about-us__intro .quote {
          width: 32px;
          top: 18px;
          left: 22px; }
        .about-us__intro::before {
          display: none; } }
  @media (min-width: 991px) {
    .about-us {
      padding-top: 83px;
      padding-bottom: 92px; }
      .about-us .main-title {
        margin-bottom: 19px; }
      .about-us__desc {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 45px;
        max-width: 761px;
        margin-left: auto;
        margin-right: auto; }
      .about-us__images {
        max-width: 1230px;
        height: 452px; }
        .about-us__images .item--1 {
          height: 270px; }
        .about-us__images .item--2 {
          height: 177px; }
        .about-us__images .item--3 {
          height: 230px; }
        .about-us__images .item--4 {
          height: 230px; }
        .about-us__images .item--5 {
          height: 214px; }
        .about-us__images .item--6 {
          height: 452px; }
      .about-us__intro {
        font-size: 20px;
        line-height: 30px;
        margin-top: 60px;
        padding-top: 0;
        max-width: 630px; }
        .about-us__intro .quote {
          top: -16px;
          left: -25px;
          width: 35px;
          height: auto; } }

.our-mission {
  color: #e6e6e6; }
  .our-mission__banner {
    position: relative; }
    .our-mission__banner:after {
      content: '';
      width: 1px;
      height: 42px;
      position: absolute;
      bottom: -24px;
      left: 50%;
      background-color: #ffffff; }
  .our-mission__title {
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #e6e6e6;
    margin-bottom: 17px; }
  .our-mission__desc {
    font-style: italic;
    font-weight: normal;
    font-size: 24px;
    line-height: 115%;
    text-align: center;
    margin-bottom: 0; }
  .our-mission__info {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.05em;
    background-color: #242d33;
    padding-top: 52px;
    padding-bottom: 42px; }
  .our-mission__caption {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: center;
            justify-content: center; }
  .our-mission__thumb {
    position: relative;
    height: 476px;
    overflow: hidden; }
    .our-mission__thumb .item {
      position: absolute;
      width: 50%;
      -o-object-fit: cover;
         object-fit: cover; }
      .our-mission__thumb .item--1 {
        top: 0;
        left: 0;
        height: 170px; }
      .our-mission__thumb .item--2 {
        top: 170px;
        left: 0;
        height: 136px; }
      .our-mission__thumb .item--3 {
        top: 306px;
        left: 0;
        height: 170px; }
      .our-mission__thumb .item--4 {
        top: 0;
        right: 0;
        height: 153px; }
      .our-mission__thumb .item--5 {
        top: 153px;
        right: 0;
        height: 170px; }
      .our-mission__thumb .item--6 {
        top: 323px;
        right: 0;
        height: 153px; }
  @media (min-width: 768px) {
    .our-mission__title {
      font-size: 12px;
      line-height: 11px;
      letter-spacing: 0.5em;
      margin-bottom: 12px; }
    .our-mission__image {
      max-height: 266px;
      -o-object-fit: cover;
         object-fit: cover; }
    .our-mission__banner:after {
      display: none; }
    .our-mission__content {
      height: 243px;
      position: relative; }
    .our-mission__info {
      width: 35.15625%;
      height: 100%;
      top: 0;
      left: 0;
      padding: 0 32px;
      text-align: left;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
              align-items: center; }
    .our-mission__thumb {
      position: static; }
      .our-mission__thumb .item {
        position: absolute;
        -o-object-fit: cover;
           object-fit: cover; }
        .our-mission__thumb .item--1 {
          width: 22.52604%;
          height: 100%;
          top: 0;
          left: 35.15625%;
          right: auto;
          bottom: auto; }
        .our-mission__thumb .item--2 {
          width: 17.96875%;
          height: 138px;
          top: 0;
          left: 57.68229%;
          bottom: auto;
          right: auto; }
        .our-mission__thumb .item--3 {
          width: 17.96875%;
          height: 105px;
          left: 57.68229%;
          bottom: 0;
          top: auto;
          right: auto; }
        .our-mission__thumb .item--4 {
          width: 13.28125%;
          height: 99px;
          top: 0;
          right: 0;
          left: auto;
          bottom: auto; }
        .our-mission__thumb .item--5 {
          width: 26.5625%;
          height: 144px;
          bottom: 0;
          right: 0;
          top: auto;
          left: auto; }
        .our-mission__thumb .item--6 {
          width: 13.28125%;
          height: 99px;
          top: 0;
          right: 13.28125%;
          bottom: auto;
          left: auto; } }
  @media (min-width: 991px) {
    .our-mission__banner {
      height: 500px; }
    .our-mission__image {
      height: 500px;
      -o-object-fit: cover;
         object-fit: cover;
      max-height: unset; }
    .our-mission__title {
      margin-bottom: 22px; }
    .our-mission__desc {
      font-size: 24px;
      line-height: 40px;
      max-width: 720px;
      margin: 0 auto; }
    .our-mission__info {
      font-size: 14px;
      line-height: 24px;
      height: 458px;
      padding: 0 6.38889%; }
    .our-mission__content {
      height: 458px; }
    .our-mission__thumb .item--2 {
      height: 260px; }
    .our-mission__thumb .item--3 {
      height: 198px; }
    .our-mission__thumb .item--4 {
      height: 187px; }
    .our-mission__thumb .item--5 {
      height: 271px; }
    .our-mission__thumb .item--6 {
      height: 187px; } }

.our-methodology {
  padding-top: 48px;
  padding-bottom: 39px;
  position: relative; }
  .our-methodology .main-title {
    margin-bottom: 18px; }
  .our-methodology .container {
    position: relative;
    z-index: 1; }
  .our-methodology .number {
    padding-top: 34px;
    padding-bottom: 19px;
    position: relative; }
    .our-methodology .number::after {
      content: '';
      width: 1px;
      height: 18px;
      position: absolute;
      bottom: 0;
      left: 50%;
      background-color: #ffffff; }
  .our-methodology__bg {
    position: absolute;
    bottom: -40px;
    right: 0; }
  .our-methodology__info {
    position: relative;
    padding-top: 52px; }
    .our-methodology__info::after {
      content: '';
      width: 1px;
      height: 18px;
      position: absolute;
      top: 0;
      left: 50%;
      background-color: #242D33; }
    .our-methodology__info .quote {
      color: #F7F7F7;
      position: absolute;
      top: 29px;
      left: 21px; }
    .our-methodology__info .text {
      font-size: 14px;
      line-height: 150%;
      text-align: center;
      color: #000000;
      opacity: 0.9;
      font-family: "PlayfairDisplay"; }
  .our-methodology__desc {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.05em;
    margin-bottom: 34px; }
  @media (min-width: 768px) {
    .our-methodology {
      padding-top: 69px;
      padding-bottom: 76px;
      position: relative;
      overflow: hidden;
      margin-bottom: 12px; }
      .our-methodology:after {
        content: '';
        width: 1px;
        height: 52px;
        background-color: #242D33;
        position: absolute;
        bottom: 0;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%); }
      .our-methodology .main-title {
        margin-bottom: 21px; }
      .our-methodology__desc {
        margin-bottom: 31px; }
      .our-methodology__bg {
        bottom: -60px;
        right: auto;
        left: 0; }
      .our-methodology__info {
        padding-top: 41px; }
      .our-methodology .number {
        padding-top: 28px;
        padding-bottom: 6px; } }
  @media (min-width: 991px) {
    .our-methodology {
      padding-top: 93px;
      padding-bottom: 100px;
      margin-bottom: 0; }
      .our-methodology:after {
        height: 80px; }
      .our-methodology .container {
        max-width: 750px; }
      .our-methodology .main-title {
        margin-bottom: 19px; }
      .our-methodology__desc {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 41px; }
      .our-methodology__info {
        padding-top: 60px; }
        .our-methodology__info .quote {
          display: none; }
        .our-methodology__info:after {
          height: 27px; }
        .our-methodology__info .text {
          font-size: 20px;
          line-height: 30px; }
      .our-methodology .number {
        padding-top: 42px;
        padding-bottom: 22px;
        max-width: 704px;
        margin: 0 auto; }
        .our-methodology .number:after {
          height: 27px; }
        .our-methodology .number .col {
          padding: 0 70px; }
        .our-methodology .number .item__title {
          font-size: 52px;
          line-height: 63px;
          margin-bottom: 11px; }
        .our-methodology .number .item__label {
          font-size: 10px;
          line-height: 12px;
          letter-spacing: 0.5em; }
        .our-methodology .number .item__plus {
          top: 4px;
          right: -25px;
          width: 19px;
          height: auto; } }
